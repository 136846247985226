<div class="app-licence-window">
    <div class="app-title">
        {{ 'licence.window.title' | translate }}
    </div>
    <div class="app-names" *ngIf="avaliableLicences.length > 0">
        <div class="app-label">{{ 'licence.window.message1' | translate }}</div>
        <ng-container *ngFor="let licence of avaliableLicences; let i = index">
            <span *ngIf="i > 0">, </span>
            <a class="app-name" (click)="openLicenceDialog(licence)">{{ licenceService.label(licence) }}
                <span class="app-index">({{i + 1}})</span>
            </a>
        </ng-container>
    </div>

    <div class="app-options" *ngIf="avaliableLicences.length > 0">
        <div class="app-label">{{ 'licence.window.message2' | translate }}</div>
        <div class="app-option" *ngFor="let licence of avaliableLicences; let i = index">
            <span class="app-index">({{i + 1}})</span>
            <app-licence-messages *ngIf="instructions[licence]" [content]="instructions[licence]"></app-licence-messages>
        </div>
    </div>

    <div class="app-text">
        <app-licence-messages [content]="text"></app-licence-messages>
    </div>


</div>