<div class="app-static-page">

  <div class="app-page-content" *ngIf="data">
    <!-- <h1>{{ 'navbar.' + page | translate }}</h1> -->
    <div [innerHTML]="data"></div>

    <div class="app-terms-controls" *ngIf="page == 'terms'">
      
      <div>
      <mat-checkbox color="primary" [(ngModel)]="termsAgreed">{{ 'terms.agreement1' | translate }}
        <span *ngIf="!termsUrl()" >{{ 'terms.agreement2' | translate }}</span>
      </mat-checkbox>
        <a *ngIf="termsUrl()" [href]="termsUrl()" target="_blank">{{ 'terms.agreement2' | translate }}</a>
      </div>
      <button mat-flat-button [disabled]="!termsAgreed" color="primary" (click)="login()">{{ 'terms.login' | translate }}</button>
    </div>
    <div *ngIf="data2" [innerHTML]="data2"></div>
  </div>

  <mat-spinner *ngIf="loading"
        class="medium-centered-spinner" 
        [strokeWidth]="3" 
        [diameter]="40">
  </mat-spinner>

  <span class="app-version" *ngIf="page == 'about'">v2.10.7</span>
  
</div>