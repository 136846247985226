<div class="sp-header">
      
  <nav class="sp-header-buttons">
    <ul>
      <li>
        <a tabindex="0" (click)="analytics.sendEvent('landing', 'help');" [routerLink]="['/', 'help']" class="sp-header-button">{{ 'sp.header.help' | translate }}</a>
      </li>

      <li class="app-language" *ngIf="languages().length > 1">
        <div tabindex="0" class="app-dropdown-language" [matMenuTriggerFor]="languagesMenu" role="button">
          <img src="assets/img/flag_{{translate.currentLang}}.png">
        </div>
      </li>
    </ul>
  </nav>


  <a tabindex="0" class="sp-header-logo" [routerLink]="['/']" [matTooltip]="'sp.logo_tooltip' | translate">
    <img [src]="'sp.header.logo' | translate">
  </a>

</div>


<mat-menu #languagesMenu="matMenu" xPosition="after" class="app-languages-menu">
  <ul [class.app-more-cols]="languages().length >= 6">
    <li class="app-dropdown-item" *ngFor="let lang of languages()" (click)="onLanguageChanged(lang)" (keydown.enter)="closeMenu()" mat-menu-item role="button" tabindex="0">
      <div class="app-language">
        <img src="assets/img/flag_{{lang}}.png">
        <div class="app-language-name">{{ languageService.getLanguageName(lang) }}</div>
      </div>
    </li>
  </ul>
</mat-menu>