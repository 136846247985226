<div class="app-map-browse">

    <div class="app-list">

        <!-- NAVIGATION TABS -->
        <div class="app-navigation-tabs" *ngIf="settings.mapSearchType == 'all'">
            <div class="app-tab app-active"
                tabindex="0"
                (click)="changeNavigationTab('maps')"
                [class.app-active]="activeNavigationTab == 'maps'">
                {{ 'search.map.maps' | translate }}
                <span>{{ searchService.getNumberOfResults() - markersCount }}</span>
            </div>
            <div class="app-tab app-active"
                tabindex="0"
                (click)="changeNavigationTab('markers')"
                [class.app-active]="activeNavigationTab == 'markers'">
                {{ 'search.map.markers' | translate }}
                <span>{{ markersCount }}</span>
            </div>
        </div>

        <div class="app-list-content" [class.app-with-tabs]="this.settings.mapSearchType == 'all'">
            <!-- IF MAPS -->
            <div *ngIf="activeNavigationTab == 'maps'" (mouseleave)="focusedItem = null">
                <ng-container *ngFor="let item of searchService.results">
                    <a class="app-maps-list-item" routerLink="{{item.url}}" (mouseenter)="focusedItem = item">
                        <div class="app-item-thumb" [style.background-image]="thumb(item.uuid)">
                            <div [matTooltip]="getLock(item).tooltip" class="app-lock {{ getLock(item).class }}" *ngIf="getLock(item)">
                                <mat-icon>{{ getLock(item).icon }}</mat-icon>
                            </div>
                        </div>
                        <div class="app-item-title">{{item.title}}</div>
                        <div *ngIf="item.date" class="app-item-date">{{item.date}}</div>
                        <div *ngIf="item.authors" class="app-item-authors">{{item.authors}}</div>
                        <div *ngIf="item.geonames" class="app-item-geonames">{{item.geonames}}</div>
                    </a>
                </ng-container>

            </div>
            
            <!-- IF GRAPHICS -->
            <div *ngIf="activeNavigationTab == 'markers' && clusterArray && clusterArray.length > 0">
                <div class="app-graphics-list-item" 
                    *ngFor="let cluster of clusterArray" 
                    >
                    <ng-container *ngIf="cluster && cluster.markers_">
                        <!-- CLUSTER -->
                        <div class="app-marker" *ngIf="cluster.markers_.length >= minimumClusterSize && (!theCluster() || cluster === theCluster())" (click)="onClusterClickFromDiv(cluster)"
                                (mouseenter)="highlightCluster($event, cluster)"
                                (mouseleave)="highlightCluster($event, null)">
                            <div class="app-item-thumb" [style.background-image]="thumb(cluster['info']['pid'])"></div>
                            <div class="app-item-title">{{ cluster['info']['title'] }}</div>
                            <div class="app-item-date">{{ cluster['info']['documents'] }} documents</div>
                            <div class="app-item-authors">{{ cluster['info']['markers'] }} locations</div>
                        </div>
                        <!-- MARKER -->
                        <ng-container
                            *ngIf="cluster.markers_.length < minimumClusterSize && (!theCluster() || cluster === theCluster())">
                            <div class="app-markers" *ngFor="let item of cluster.markers_"
                                (mouseenter)="highlightMarker($event, cluster, item)"
                                (mouseleave)="highlightMarker($event, null, null)">
                                <ng-conteiner *ngIf="!theMarker() || item === theMarker()">
                                    <a class="app-marker" 
                                        *ngFor="let doc of findMarkerByPosition(
                                        item.getPosition().toJSON().lat,
                                        item.getPosition().toJSON().lng)"
                                        routerLink="{{doc.url}}">
                                        <div class="app-item-thumb" [style.background-image]="thumb(doc.pid)"></div>
                                        <div class="app-item-title">{{doc.title}}</div>
                                        <div *ngIf="doc.date" class="app-item-date">{{doc.date}}</div>
                                        <div *ngIf="doc.authors" class="app-item-authors">{{doc.authors}}</div>
                                        <div *ngIf="doc.geonames" class="app-item-geonames">{{doc.geonames}}</div>
                                    </a>
                                </ng-conteiner>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="app-map-wrapper">
        <google-map *ngIf="ms.mapReady"
                    #googleMap
                    [options]="ms.browseOptions" 
                    width="100%" 
                    height="100%"
                    (mapClick)="onMapClick()"
                    (idle)="onIdle()">

            <!-- <map-marker *ngIf="focusedItem && focusedItem.isPoint()" [position]="getPoint()"></map-marker> -->

            <map-marker-clusterer
                #markerCluster
                [styles]="ms.clusterOptions"
                [zoomOnClick]="true"
                [minimumClusterSize]="minimumClusterSize"
                [calculator]="myCalculator"
                (clusteringend)="onClusteringEnd(markerCluster)"
                (clusterClick)="onClusterClick(markerCluster)"
                >               
                <map-marker
                    #somemarker="mapMarker"
                    *ngFor="let marker of points"
                    [position]="marker.position"
                    [label]="{text: labelToString(marker.items.length), color: 'white', fontSize: '12px'}"
                    [options]="ms.markerOptions"
                    (mapMouseover)="onMouseOverMarker(somemarker)"
                    (mapMouseout)="onMouseOutMarker(somemarker)"
                    (mapClick)="onClickMarker(somemarker)"
                    >
                </map-marker>
            </map-marker-clusterer>

            <map-rectangle *ngIf="focusedItem && !focusedItem.isPoint()" 
                            [bounds]="getBounds()"
                            [options]="ms.boxOptions"
                            >
            </map-rectangle>

        </google-map>
    </div>

</div>