<div class="app-logo" id="app-search-dropdown-selector">

  <a [routerLink]="settings.getRouteFor('')" (click)="analytics.sendEvent('navbar', 'home');" class="brand-logo" title="{{'navbar.home' | translate}}">
  
  <span class="app-brand-img">
    <img src="{{ settings.logo }}" class="img-responsive app-pipe" alt="{{'navbar.home' | translate }}" />
  </span>

  <span class="app-brand-text hide-on-small-only">{{ settings.getTitle() }}</span>
  
  </a>  
  <mat-icon *ngIf="settings.multiKramerius && state.atSearchScreen()" 
            [matMenuTriggerFor]="librariesMenu" 
            class="app-dropdown-selector"
            [matTooltip]="'search.switch_kramerius' | translate">
            expand_more
  </mat-icon>
  </div>



  <mat-menu #librariesMenu="matMenu" xPosition="before" class="app-libraries-menu">
    <ng-template matMenuContent ngFor>
      <div class="app-dropdown-item" *ngFor="let kramerius of settings.krameriusList" (click)="changeLibrary(kramerius)">
          <img src="{{ kramerius.logo }}" class="app-library-icon" />
          <div class="app-library-name">{{ settings.getTitleForKramerius(kramerius) }} </div>
      </div>
    </ng-template>
  </mat-menu>
