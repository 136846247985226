<div class="app-browse-toolbar row">
  <!-- Show just for small devices -->
  <div class="col s2 app-col-left hide-on-med-and-up">
    <a (click)="toggleFilters()">
      <mat-icon *ngIf="browseService.activeMobilePanel == 'results'">filter_alt</mat-icon>
      <mat-icon *ngIf="browseService.activeMobilePanel == 'filters'" class="grey-text">filter_alt_off</mat-icon>
    </a>
  </div>
  <div class="col s10 m12 l12 xl12 app-col-right">
    <div class="app-ordering">
      <span class="app-label">{{ 'browse.ordering.label' | translate }}: </span>
      <span class="app-dropdown" tabindex="0" [matMenuTriggerFor]="orderingMenu">
        <span>{{ 'browse.ordering.' + browseService.query.ordering | translate }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </div>
  </div>
</div>

<mat-menu #orderingMenu="matMenu" xPosition="after" class="app-ordering-menu app-dropdown-menu">
    <div mat-menu-item tabindex="0" class="app-dropdown-item" (click)="browseService.changeOrdering('occurrence')">
      {{ 'browse.ordering.occurrence' | translate }}
    </div>
    <div mat-menu-item tabindex="0" class="app-dropdown-item" (click)="browseService.changeOrdering('alphabetical')">
      {{ 'browse.ordering.alphabetical' | translate }}
    </div>
</mat-menu>