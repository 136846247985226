<div class="row app-home-search-bar">
  <div class="col s12">
    <!-- <app-autocompleter [placeholder]="getPlaceholder()" [threshold]="1"
      [data]="autocompleterResults" [highlightColor]="'#0276bd'" (inputTermEntered)="onSearch($event, 'return')"
      [inputClass]="'autocompleter-home'" [alwaysOpen]="true"
      (autocompleterTermEntered)="onSearch($event, 'selection')" (inputTermChanged)="getAutocompleteResults($event)"
      [inputTerm]="searchStr" (inputTermCleared)="cleanQuery()"
      [actionIcon]="'info_outline'"
      [autofocus]="true"
      [actionText]="'searchbar.help' | translate"
      (actionPerformed)="showHelp()"
      (inputTermSearched)="onSearch($event, 'icon')">
    </app-autocompleter> -->
    <!-- <app-autocompleter [placeholder]="getPlaceholder()" [threshold]="1"
      [data]="autocompleterResults" [highlightColor]="'#0276bd'" (inputTermEntered)="onSearch($event, 'return')"
      [inputClass]="'autocompleter-home'" [alwaysOpen]="true"
      (autocompleterTermEntered)="onSearch($event, 'selection')" (inputTermChanged)="getAutocompleteResults($event)"
      [inputTerm]="searchStr" (inputTermCleared)="cleanQuery()"
      [actionIcon]="recording ? 'stop' : 'mic'"
      [autofocus]="true"
      [actionText]="'Mluvte'"
      (actionPerformed)="toggleRecording()"
      (inputTermSearched)="onSearch($event, 'icon')">
    </app-autocompleter> -->
    <app-autocompleter #autocompleter
      [placeholder]="getPlaceholder()"
      [threshold]="1"
      [data]="autocompleterResults" [highlightColor]="'#0276bd'" (inputTermEntered)="onSearch($event, 'return')"
      [inputClass]="'autocompleter-home'" [alwaysOpen]="true"
      (autocompleterTermEntered)="onSearch($event, 'selection')" (inputTermChanged)="getAutocompleteResults($event)"
      [inputTerm]="searchStr" (inputTermCleared)="cleanQuery()"
      [actionIcon]="micActionIcon()"
      [autofocus]="true"
      (actionPerformed)="toggleBrowserRecording()"
      (inputTermSearched)="onSearch($event, 'icon')">
    </app-autocompleter>
    <div class="app-search-bottom">
      <mat-checkbox *ngIf="accessibilityFilterEnabled()" color="primary" (change)="onAccessibilityFilterChanged()" [(ngModel)]="accessibilityFilter">{{ 'home.public_filter' | translate }}</mat-checkbox>
      <mat-checkbox *ngIf="preselectedLicencesFilterEnabled()" color="primary" (change)="onPreselectedLicencesFilterChanged()" [(ngModel)]="preselectedLicencesFilter">{{ 'home.preselected_licences_filter' | translate }}</mat-checkbox>
      <div class="app-search-help" (click)="showHelp()">
        {{ 'searchbar.help' | translate }}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col s12">
    <button (click)="onSearchButtonClick()" mat-flat-button color="primary">{{ (!!searchStr ? 'home.btn_search' : 'home.btn_enter') | translate }}</button>
  </div>
</div>