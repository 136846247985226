<div class="app-browse-results">
  <a *ngFor="let item of browseService.results; let odd=odd; let even=even;" 
        class="app-browse-item" 
        [ngClass]="{ odd: odd, even: even, hidden: !item.name}"
        [routerLink]="appSettings.getRouteFor('search')" [queryParams]="getParams(item.value)"
        >
    <div class="float-right">{{item.count}}x</div>
    <div [innerHtml]="item.name | appUpcase"></div>
  </a>

  <app-paginator [activeIndex]="browseService.query.page"
                [overallCount]="browseService.getNumberOfResults()"
                [step]="browseService.query.getRows()"
                [displayRows]="displayRows"
                (next)="browseService.nextPage()"
                (previous)="browseService.previousPage()"
                (change)="browseService.onPage($event)">
  </app-paginator> 

  <mat-spinner class="medium-centered-spinner"  *ngIf="browseService.loading" [strokeWidth]="3" [diameter]="40">
  </mat-spinner>


</div>
