<div class="app-licence-dialog app-dialog">

  <div class="app-dialog-content">
    <app-licence-messages [title]="title" [content]="text"></app-licence-messages>
  </div>

  <div class="app-dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'common.close' | translate }}</button>
  </div>

</div>



