<div class="app-search-bar-wrapper" [class.app-hover]="searchStr">
  <div class="input-field">
    <label for="autocomplete-input" class="app-hidden-label">{{ getPlaceholder() }}</label>
    <ng2-completer  #completer (keyup)="onKeyUp($event)" (selected)="onSelected($event)" [(ngModel)]="searchStr" [matchClass]="'highlight'"
    [datasource]="service" [minSearchLength]="1" [inputId]="'autocomplete-input'" [inputClass]="'autocomplete'" [placeholder]="getPlaceholder()" [inputName]="'autocomplete-input'">
    </ng2-completer>
  </div>

  <button (click)="onMagnifyIconClick()" class="app-button app-button-search" mat-icon-button [attr.aria-label]="'searchbar.search' | translate" [matTooltip]="'searchbar.search' | translate">
    <mat-icon>search</mat-icon>
  </button>

  <button *ngIf="searchStr" (click)="cleanQuery()" class="app-button app-button-close" mat-icon-button [attr.aria-label]="'searchbar.delete_query' | translate" [matTooltip]="'searchbar.delete_query' | translate">
    <mat-icon>close</mat-icon>
  </button>

  <button (click)="showHelp()" class="app-button app-button-help" mat-icon-button [attr.aria-label]="'searchbar.help' | translate" [matTooltip]="'searchbar.help' | translate">
    <mat-icon>info_outline</mat-icon>
  </button>

</div>