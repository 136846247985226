<div class="app-music-controls">

  <div class="app-player">

  <div #progressContainer class="app-progress-container" (click)="onProgressClick($event)">
      <div class="app-progress" [style.width]="(musicService.progress * 100) + '%'"></div>
  </div>

      <div class="app-player-left">
        <ng-container *ngIf="musicService.activeTrack">
          <div class="app-player-image">
              <img [src]="soundUnitImage()" [alt]="'Image'">
          </div>
          <div class="app-player-track">{{ musicService.activeTrack.name }}</div>
          <div class="app-player-album">
              <div class="app-album-title">{{ musicService.activeTrack.unit.name }}</div>
          </div>
        </ng-container>
      </div>

      <div class="app-player-center">
        <ng-container *ngIf="!musicService.error && musicService.canPlay">
          <div class="app-player-buttons">
              <mat-icon *ngIf="!musicService.isPlaying()" (click)="play()" class="app-player-play">play_circle</mat-icon>
              <mat-icon *ngIf="musicService.isPlaying()" (click)="pause()" class="app-player-play">pause_circle</mat-icon>
          </div>
          <div class="app-player-duration" *ngIf="musicService.trackDurationText">
              {{ musicService.trackPositionText }} / {{ musicService.trackDurationText }}
          </div>
        </ng-container>
        <div class="app-player-error" *ngIf="musicService.error">
          {{ 'music.' + musicService.error | translate }}
        </div>
      </div>
  </div>
</div>