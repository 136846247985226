<div class="app-content-wrapper">
  <section class="container center-align home-top">
    <div class="row">
      <div class="col s12">
        <app-home-logo></app-home-logo>
      </div>
    </div>
    <ng-container *ngIf="settings.getSubtitle()">
      <div class="row" *ngIf="settings.getSubtitle()">
        <div class="col offset-s1 s10 offset-s1 offset-m3 m6 offset-m3 offset-l3 l6 offset-l3 offset-xl4 xl4 offset-xl4">
          <div class="divider"></div>
        </div>
      </div>
      <div class="row">
        <h1 class="col s12 app-desc">{{ settings.getSubtitle() }}</h1>
      </div>
    </ng-container>
    <app-home-search-bar></app-home-search-bar>
  </section>

  <div *ngIf="settings.curatorListsEnabled" class="container app-featured">
    <app-curator-lists></app-curator-lists>
  </div>
  
  <div *ngIf="!settings.curatorListsEnabled" class="container app-featured">
    <app-banner></app-banner>

    <div class="app-tabs" [class]="'app-tabs-' + numberOfTabs()">
      <div *ngIf="visited.length > 0" class="app-tab" tabindex="0" [class.app-active]="selectedTab === 'visited'" (click)="changeTab('visited')">
        {{ 'home.tab_visited' | translate }}
      </div>
      <div class="app-tab" tabindex="0" [class.app-active]="selectedTab === 'newest'" (click)="changeTab('newest')">
        {{ 'home.tab_latest' | translate }}
      </div>
      <div *ngIf="recommended.length > 0" class="app-tab" tabindex="0" [class.app-active]="selectedTab === 'recommended'" (click)="changeTab('recommended')">
        {{ 'home.tab_recommended' | translate }}
      </div>
    </div>

    <div class="app-tab-content row">
      <app-document-card *ngFor="let item of getActiveItems()" [in]="'home'" [item]="item" class="col s12 m6 l6 xl4"></app-document-card>
    </div>

    <app-paginator 
        [activeIndex]="page"
        [overallCount]="getNumberOfActiveItems()"
        [step]="step"
        [displayRows]="displayRows"
        (next)="page = page + 1"
        (previous)="page = page - 1"
        (change)="page = $event">
    </app-paginator> 
  </div>

  <footer class="container app-footer" *ngIf="showFooter">
      <app-home-footer></app-home-footer>
  </footer>


</div>