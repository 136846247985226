<div *ngIf="state != 'loading'" class="app-collections">
  
  <ng-container *ngIf="_uuids.length == 1">
    <div class="app-label">{{ collectionsIn.length == 0 ? "Není v žádné sbírce" : "Je ve sbírkách" }}</div>
    <div class="app-collection-list">
      <div *ngFor="let col of collectionsIn" class="app-collection">
        <div class="app-btn app-btn-remove" (click)="removeFromCollectionDialog(col)">-</div>
        <span class="app-col-name">{{ col.name }}</span>
        <span class="app-col-desc">{{ col.desc }}</span>
      </div>
    </div>
  </ng-container>
  <input *ngIf="_uuids.length < 2" autocapitalize="off" autocomplete="off" autocorrect="off"  placeholder="Přidat do sbírky" [(ngModel)]="query" (input)="onCollectionSearchChange($event.target.value)">
  <input *ngIf="_uuids.length > 1" autocapitalize="off" autocomplete="off" autocorrect="off"  placeholder="Přidat/odebrat všechny vybrané objekty do/ze sbírky" [(ngModel)]="query" (input)="onCollectionSearchChange($event.target.value)">
  <div class="app-collection-list">
    <div *ngFor="let col of collectionsRest" class="app-collection">
      <div class="app-btn" (click)="addToCollectionDialog(col)">+</div>
      <div class="app-btn app-btn-remove" *ngIf="_uuids.length > 1" (click)="removeFromCollectionDialog(col)">-</div>
      <span class="app-col-name">{{ col.name }}</span>
      <span class="app-col-desc">{{ col.desc }}</span>
    </div>
  </div>

  <div class="app-actions">
    <mat-spinner *ngIf="state == 'progress'"
      [strokeWidth]="2" 
      [diameter]="20">
    </mat-spinner>
  </div>

</div>