<a [routerLink]="settings.getRouteFor(item.getPath())" [queryParams]="periodicalService.getUrlParams()" (click)="analytics.sendEvent('periodical', 'open from', 'grid');">

  <div class="app-card-content center-align">
    <h3 class="app-card-title">{{ item.getTitle() }}</h3>
    <div class="app-card-thumb" [scrollTarget]="container" [lazyLoad]="item.thumb" >
      <div matTooltipPosition="above" [matTooltip]="lock.tooltip" class="app-lock {{ lock.class }}" *ngIf="lock">
        <mat-icon>{{ lock.icon }}</mat-icon>
      </div>
    </div>
    <div class="app-card-subtitle" [matTooltip]="item.getExtendedPart(translate)">
      <ng-container *ngIf="item.getPart().length < 10; else longPart">
        <div [translate]="'periodical.' + item.doctype" [translateParams]="{ part: item.getPart() }"></div>
      </ng-container>
      <ng-template #longPart>{{ item.getPart() }}</ng-template>
    </div>
  </div>

  <div *ngIf="selectable" class="app-selectable" [class.app-selected]="item.selected"
      (click)="item.selected = !item.selected; periodicalService.itemSelection(); $event.preventDefault();$event.stopPropagation()">
      <div class="app-check"></div>
  </div>

</a>
