<div class="app-dialog-wrapper">
    <div mat-dialog-content>
        <div class="app-dialog-title">{{ data.title | translate }}</div>
        <div class="app-dialog-message">{{ data.message | translate }} {{data.name}}?</div>
      </div>
    <div mat-dialog-actions>
        <button *ngIf="warn" mat-flat-button color="warn" [mat-dialog-close]="data.confirm" cdkFocusInitial>{{ 'folders.dialogs.confirm' | translate }}</button>
        <button *ngIf="!warn" mat-flat-button color="primary" [mat-dialog-close]="data.confirm" cdkFocusInitial>{{ 'folders.dialogs.confirm' | translate }}</button>
        <button mat-button (click)="onNoClick()">{{ 'folders.dialogs.cancel' | translate }}</button>
    </div>
</div>