<app-book-toolbar class="hide-on-med-and-up"></app-book-toolbar>

<div class="app-book-wrapper">
  <app-navigation *ngIf="bookService.showNavigationPanel"
    [class.app-panel-hidden]="!viewerControls.leftPanelVisible"
    [class.app-panel-tablet-visible-right]="!viewerControls.rightPanelVisible"
    [class.app-panel-mobile-hidden]="bookService.activeMobilePanel != 'navigation'">
  </app-navigation>
  
  <app-epub-viewer *ngIf="bookService.viewer === 'epub'"></app-epub-viewer>
  <app-viewer *ngIf="bookService.viewer === 'image' && !bookService.showGeoreference"></app-viewer>
  <app-map-viewer *ngIf="bookService.showGeoreference" [uuid]="bookService.geoUuid" [data]="bookService.geoData"></app-map-viewer>
  <app-pdf-viewer2 *ngIf="bookService.viewer === 'pdf'"></app-pdf-viewer2>

  <app-metadata *ngIf="!bookService.isDocLoading()"
    [class.app-panel-hidden]="!viewerControls.rightPanelVisible" 
    [class.app-panel-tablet-visible-left]="!viewerControls.leftPanelVisible"
    [class.app-panel-mobile-hidden]="bookService.activeMobilePanel != 'metadata'" 
    class="app-book-metadata" 
    [metadata]="bookService.metadata">
      <app-book-controls></app-book-controls>
  </app-metadata>
</div>

<mat-spinner *ngIf="bookService.isDocLoading()" 
  class="medium-centered-spinner" 
  [strokeWidth]="3" 
  [diameter]="40">
</mat-spinner>
