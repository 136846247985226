<div id="app-periodical-fulltext-container" class="app-periodical-fulltext-layout-wrapper">
  <h2 class="app-visualy-hidden">{{ 'search.results' | translate }}</h2>
  <ul>
    <li *ngFor="let item of periodicalService.fulltext.pages">
      <app-periodical-fulltext-item [item]="item"></app-periodical-fulltext-item>
    </li>
  </ul>
  <app-alert *ngIf="periodicalService.getNumberOfResults() === 0"
    type="warning"
    label="alert.warning"
    message="alert.no_results_found">
  </app-alert>

  <app-paginator [activeIndex]="periodicalService.fulltext.page"
          [overallCount]="periodicalService.fulltext.results"
          [step]="periodicalService.fulltext.limit"
          [displayRows]="displayRows"
          (next)="periodicalService.nextFtPage()"
          (previous)="periodicalService.previousFtPage()"
          (change)="periodicalService.onFtPage($event)">
  </app-paginator> 

</div>