<div id="app-viewer" class="app-viewer-wrapper" (mousemove)="onMouseMove()" (click)="onMouseMove()">

    <app-viewer-controls [class.hide-on-inactivity]="hideOnInactivity"></app-viewer-controls>
    

    <angular-epub-viewer #epubViewer style="height: 100%;
    width: 100%;padding: 25px; display: block;background: #fff;"
    [autoTOC]="true"
    [autoMetadata]="true"
    [autoPagination]="true"
    (mousemove)="onMouseMove()"
    (onSearchFinished)="epub.onSearchFinished($event)"
    (onLocationFound)="epub.onLocationFound($event)"
    (onPaginationComputed)="epub.onPaginationComputed($event)"
    (onTOCLoaded)="epub.onTOCLoaded($event)"
    (onMetadataLoaded)="onMetadataLoaded($event)"
    (onDocumentReady)="onDocumentReady($event)"
    ></angular-epub-viewer>
   
    <span *ngIf="controlsService.leftPanelVisible && !controlsService.fullscreenEnabled()"
    class="app-handle app-left-handle valign-wrapper hide-on-small-only" 
    (click)="controlsService.hideLeftPanel()" 
    [matTooltip]="'view_controls.tooltip_hide_left' | translate">
    <mat-icon>arrow_left</mat-icon>
    </span>
    
    <span *ngIf="!controlsService.leftPanelVisible && !controlsService.fullscreenEnabled()"
    class="app-handle app-left-handle valign-wrapper hide-on-small-only" 
    (click)="controlsService.showLeftPanel()" 
    [matTooltip]="'view_controls.tooltip_show_left' | translate">
    <mat-icon>arrow_right</mat-icon>
    </span>
    
    <span *ngIf="controlsService.rightPanelVisible && !controlsService.fullscreenEnabled()"
        class="app-handle app-right-handle valign-wrapper hide-on-small-only" 
        (click)="controlsService.hideRightPanel()" 
        [matTooltip]="'view_controls.tooltip_hide_right' | translate">
        <mat-icon>arrow_right</mat-icon>
    </span>
    
    <span *ngIf="!controlsService.rightPanelVisible && !controlsService.fullscreenEnabled()"
    class="app-handle app-right-handle valign-wrapper hide-on-small-only" 
    (click)="controlsService.showRightPanel()" 
    [matTooltip]="'view_controls.tooltip_show_right' | translate">
    <mat-icon>arrow_left</mat-icon>
    </span>


    <ng-container *ngIf="bookService.isPageInaccessible()">
        <app-licence-window [licences]="bookService.licences"></app-licence-window>
  </ng-container>
  
  <app-alert *ngIf="bookService.isPageFailure()"
      type="danger"
      label="alert.error"
      message="alert.failed_to_load_image">
  </app-alert>
  
  <mat-spinner *ngIf="bookService.isPageLoading()" 
      class="medium-centered-spinner" 
      [strokeWidth]="3"
      [diameter]="40">
  </mat-spinner>



</div>

