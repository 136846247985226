
<div class="app-cookiebar">
  <div class="app-title">{{ 'cookiebar.title' | translate }}</div>

  <div class="app-message">
    {{ 'cookiebar.message' | translate }}
    <div *ngIf="!showDetail" class="app-show-detail" (click)="showDetails()">{{ 'cookiebar.show_details' | translate }}</div>
  </div>

  <div class="app-detail" *ngIf="showDetail">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <div class="app-wrapper">
          <mat-checkbox color="primary" [(ngModel)]="functional" [disabled]="true"></mat-checkbox>
          <span>{{ 'cookiebar.functional.label' | translate }}</span>
        </div>
      </mat-expansion-panel-header>
      <div class="app-info-content">{{ 'cookiebar.functional.description' | translate }}</div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <div class="app-wrapper" (click)="preferential = !preferential; $event.stopPropagation();">
          <mat-checkbox disableRipple (click)="$event.stopPropagation();" color="primary" [(ngModel)]="preferential"></mat-checkbox>
          <span>{{ 'cookiebar.preferential.label' | translate }}</span>
        </div>
      </mat-expansion-panel-header>
      <div class="app-info-content">{{ 'cookiebar.preferential.description' | translate }}</div>
    </mat-expansion-panel>


    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
          <div class="app-wrapper" (click)="analytical = !analytical; $event.stopPropagation();">
            <mat-checkbox (click)="$event.stopPropagation();" disableRipple color="primary" [(ngModel)]="analytical"></mat-checkbox>
            <span>{{ 'cookiebar.analytical.label' | translate }}</span>
          </div>
      </mat-expansion-panel-header>
      <div class="app-info-content">{{ 'cookiebar.analytical.description' | translate }}</div>
    </mat-expansion-panel>


  </div>

  <div class="app-buttons" *ngIf="!showDetail">
    <button mat-flat-button color="primary" (click)="acceptAll()">{{ 'cookiebar.accept_all' | translate }}</button>
    <button mat-stroked-button color="primary" (click)="rejectAll()">{{ 'cookiebar.reject_all' | translate }}</button>
  </div>

  <div class="app-buttons" *ngIf="showDetail">
    <button mat-flat-button color="primary" (click)="acceptSelected()">{{ 'cookiebar.accept_selected' | translate }}</button>
    <button mat-stroked-button (click)="hideDetails()">{{ 'cookiebar.hide_details' | translate }}</button>
  </div>




</div>