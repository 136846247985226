<div class="app-periodical-content-wrapper" *ngIf="periodicalService.isStateSuccess()">
  <app-periodical-toolbar></app-periodical-toolbar>

  <ng-container *ngIf="!periodicalService.fulltext">
    <h2 class="app-visualy-hidden">{{ 'search.results' | translate }}</h2>
    <app-periodical-unit-layout
      *ngIf="periodicalService.activeLayout=='title'">
    </app-periodical-unit-layout>  

    <app-periodical-grid-layout
      *ngIf="periodicalService.activeLayout=='grid'">
    </app-periodical-grid-layout>  

    <app-periodical-years-layout
      *ngIf="periodicalService.activeLayout=='years'">
    </app-periodical-years-layout>    

    <app-periodical-calendar-layout
      *ngIf="periodicalService.activeLayout=='calendar'">
    </app-periodical-calendar-layout> 

  </ng-container>

  <app-periodical-fulltext-layout
    *ngIf="periodicalService.fulltext">
  </app-periodical-fulltext-layout> 

</div>