<div class="brand-logo" [class.app-logo-big]="appSettings.bigHomeLogo" *ngIf="!appSettings.multiKramerius">
  <span class="app-brand-img app-brand-top">
    <img src="{{ appSettings.logoHome }}" class="img-responsive app-pipe" alt="{{ appSettings.getTitle() }}" />
  </span>
  <span [class.app-brand-text-hide]="appSettings.hideHomeTitle" class="app-brand-text app-brand-top app-single-kramerius">{{ appSettings.getTitle() }}</span>
</div>

<div class="brand-logo" [class.app-logo-big]="appSettings.bigHomeLogo" *ngIf="appSettings.multiKramerius && appSettings.landingPage">
  <a [matTooltip]="'home.select_library' | translate" matTooltipPosition="above" [routerLink]="['/']" (click)="analytics.sendEvent('home', 'landing', appSettings.code);">
    <span>
      <span class="app-brand-img app-brand-top">
        <img src="{{ appSettings.logoHome }}" class="img-responsive app-pipe" alt="{{ appSettings.getTitle() }}" />
      </span>
      <span *ngIf="!appSettings.hideHomeTitle" class="app-brand-text app-brand-top" aria-hidden="true">{{ appSettings.getTitle() }}</span>
      <mat-icon class="app-brand-handle" aria-hidden="true">expand_more</mat-icon>
    </span>
  </a>
</div>

<div class="brand-logo" [class.app-logo-big]="appSettings.bigHomeLogo" *ngIf="appSettings.multiKramerius && !appSettings.landingPage">
  <div [matMenuTriggerFor]="librariesMenu" [matTooltip]="'home.select_library' | translate" matTooltipPosition="above">
    <span>
      <span class="app-brand-img app-brand-top">
        <img src="{{ appSettings.logoHome }}" class="img-responsive app-pipe" alt="{{ appSettings.getTitle() }}" />
      </span>
      <span *ngIf="!appSettings.hideHomeTitle" class="app-brand-text app-brand-top" aria-hidden="true">{{ appSettings.getTitle() }}</span>
      <mat-icon class="app-brand-handle" aria-hidden="true">expand_more</mat-icon>
    </span>
  </div>

  <mat-menu #librariesMenu="matMenu" xPosition="after" class="app-home-libraries-menu">
    <ng-template matMenuContent ngFor>
      <a class="app-dropdown-item" *ngFor="let kramerius of appSettings.krameriusList" [routerLink]="['/' + kramerius.code]" (click)="analytics.sendEvent('home', 'kramerius', appSettings.getTitleForAnalytics(kramerius));">
        <div class="app-brand-img">
          <img src="{{ kramerius.logo }}" class="img-responsive app-pipe" alt="{{ appSettings.getTitleForKramerius(kramerius)}}" />
        </div>
        <div class="app-brand-text">{{ appSettings.getTitleForKramerius(kramerius) }}</div>
      </a>
    </ng-template>
  </mat-menu>

</div>




