<a *ngIf="collection" routerLink="/search" [queryParams]="{ collections: collection.pid }" (click)="analytics.sendEvent('collections', 'open', collection.title);">
  <div class="app-card-wrapper">
    <div class="app-card-media">
      <div class="app-card-thumb" [style.background-image]="getThumb()"></div>
    </div>
    <div class="app-card-content">
      <div  class="app-card-title">{{ collection.title}}</div>
      <div class="app-card-description">{{ collection.description}}</div>
      <div *ngIf="collection && collection.count" class="app-card-count">
        <strong>{{collection.count}}</strong>
        <span>{{ 'common.num_of_docs' | appPlural : collection.count }}</span>
      </div>
    </div>
  </div>
</a>
