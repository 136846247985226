<app-browse-count></app-browse-count>

<app-browse-search></app-browse-search>

<mat-accordion [displayMode]="'flat'" [multi]="true" class="app-filters-wrapper">

  <mat-expansion-panel class="app-filter-panel" [expanded]="true" *ngIf="accessibilityEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'search.accessibility.label' | translate }}   
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="app-filter-items">

      <div class="app-filter-item" tabindex="0" [class.app-selected]="browseService.query.accessibility == 'public'"
      (click)="browseService.setAccessibility('public');analytics.sendEvent('browse', 'accessibility', 'public');">
        {{'search.accessibility.public' | translate }}
      </div>

      <div class="app-filter-item" tabindex="0" [class.app-selected]="browseService.query.accessibility == 'private'"
      (click)="browseService.setAccessibility('private');analytics.sendEvent('browse', 'accessibility', 'private');">
        {{'search.accessibility.private' | translate }}
      </div>
    
      <div class="app-filter-item" tabindex="0" [class.app-selected]="browseService.query.accessibility == 'all'"
      (click)="browseService.setAccessibility('all');analytics.sendEvent('browse', 'accessibility', 'all');">
        {{'search.accessibility.all' | translate }}
      </div>
      
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="app-filter-panel" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'browse.category.label' | translate }}   
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="app-filter-items">
      <div *ngFor="let filter of filters" tabindex="0" class="app-filter-item" [class.app-selected]="browseService.query.category == filter"
      (click)="browseService.setCategory(filter);analytics.sendEvent('browse', 'category', filter);">
        {{'browse.category.' + filter | translate }}
      </div>
    </div>

  </mat-expansion-panel>

</mat-accordion>

