<a *ngIf="_item.uuid; else missing" [routerLink]="settings.getRouteFor(_item.getPath())" [queryParams]="periodicalService.getUrlParams()" (click)="analytics.sendEvent('periodical', 'open from', 'year');">
  <div class="app-card-content">
    <h3>{{ _item.getDate() }}</h3>
    <div matTooltipPosition="above" [matTooltip]="lock.tooltip" class="app-lock {{ lock.class }}" *ngIf="lock">
      <mat-icon>{{ lock.icon }}</mat-icon>
    </div>
  </div>
</a>
<ng-template #missing>
  <a class="app-missing">
  <div class="app-card-content">
      <h3>{{ _item.date }}</h3>
  </div>
  </a>
</ng-template>