<div class="app-tts-dialog">
  

  <button (click)="close()" class="app-close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
 </button>


  <h1>
    <span>{{ 'settings.title' | translate }}</span> 
    <span style="color: #aaa; padding: 0 7px;">/</span>
    <span style="color: #0277bd">{{ 'settings.tts.title' | translate }}</span>
  </h1>

  <div class="app-content">

  
  <!-- <h2>Převod textu na řeč</h2> -->

  <div class="app-info">
      {{ 'settings.tts.text1' | translate }}
      <br>
      {{ 'settings.tts.text2' | translate }}
  </div>


  <div class="app-content-in">


    <div class="app-table">
      <div class="app-table-row app-table-header">
        <div class="app-table-col app-table-col-flag"></div>
        <div class="app-table-col app-table-col-language">{{ 'settings.tts.language' | translate }}</div>
        <div class="app-table-col app-table-col-primary"></div>
        <div class="app-table-col app-table-col-voice">{{ 'settings.tts.voice' | translate }}</div>
        <div class="app-table-col app-table-col-play">{{ 'settings.tts.play' | translate }}</div>
        <div class="app-table-col app-table-col-actions"></div>
      </div>

      <div class="app-table-row" *ngFor="let language of tts.activeLanguages">
        <div class="app-table-col app-table-col-flag">
          <img src="assets/img/flag_{{language.code}}.png">
        </div>
        <div class="app-table-col app-table-col-language">{{ languageService.getLanguageName(language.code) }}</div>
        <div class="app-table-col app-table-col-primary">
          <div [ngClass]="{'app-tag-active': tts.primaryLanguage == language.code, 'app-tag-inactive': tts.primaryLanguage != language.code}" (click)="tts.setPrimaryLanguage(language.code)">{{ 'settings.tts.primary' | translate }}</div>
        </div>
        <div class="app-table-col app-table-col-voice">
          <div class="app-voice" [matMenuTriggerFor]="voicesMenu" (click)="setVoiceMenu(language); stopReading()">
            {{language.voice.name}}
            <span class="app-gender">{{language.voice.gender == 'male' ? 'M' : 'F'}}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <div class="app-table-col app-table-col-play">
          <mat-icon *ngIf="!isVoiceReading(language.code, language.voice, 'table')" class="app-play" (click)="read(language.code, language.voice, 'table')">volume_up</mat-icon>
          <mat-icon *ngIf="isVoiceReading(language.code, language.voice, 'table') && readingVoiceState == 'loading'" class="app-play app-loading">sync</mat-icon>
          <mat-icon *ngIf="isVoiceReading(language.code, language.voice, 'table') && readingVoiceState == 'reading'" class="app-play app-reading" (click)="stopReading()">stop</mat-icon>
        </div>
        <div class="app-table-col app-table-col-actions"><mat-icon class="app-delete" (click)="tts.removeActiveLanguage(language.code)" [ngClass]="{'app-disabled': tts.primaryLanguage == language.code}">delete</mat-icon></div>
      </div>
    </div>

    <div class="app-button-add" [matMenuTriggerFor]="languagesMenu">
      <mat-icon>add</mat-icon>
      {{ 'settings.tts.add_language' | translate }}
    </div>



      <!-- <div class="app-section app-section-languages">
        <div class="app-table">
          <div class="app-row app-header">
            <div class="app-col" style="width: 100px;">Primární jazyk</div>
          </div>
          <div class="app-row" *ngFor="let l of languages" (click)="onLanguageChange(l)" [class.app-active]="l == language">
            <div class="app-col" style="width: 35px;">
              <mat-icon class="app-rb">{{ 'radio_button_' + (l == language ? 'checked' : 'unchecked') }}</mat-icon>
            </div>
            <div class="app-col" style="width: 145px;">
              {{ languageService.getLanguageName(l) }}</div>
          </div>
        </div>
    </div> -->

    <!-- <div class="app-section app-section-voices">
        <div class="app-table">
          <div class="app-row app-header">
            <div class="app-col" style="width: 160px;">Hlas</div>
            <div class="app-col" style="width: 55px;">Gender</div>
            <div class="app-col" style="width: 57px;">Zdroj</div>
            <div class="app-col" style="width: 28px; text-align: right;">Test</div>

          </div>
          <div class="app-row" *ngFor="let v of voices[language]" (click)="onVoiceChange(v)" [class.app-active]="v == voice">
            <div class="app-col" style="width: 35px;">
              <mat-icon class="app-rb">{{ 'radio_button_' + (v == voice ? 'checked' : 'unchecked') }}</mat-icon>
            </div>
            <div class="app-col" style="width: 150px;">{{ v.name }}</div>
            <div class="app-col" style="width: 30px;">{{ v.gender == 'male' ? 'M' : 'F' }}</div>
            <div class="app-col" style="width: 65px;">{{ v.source }}</div>
            <div class="app-col" style="width: 20px;"><mat-icon class="app-play" (click)="read(v); $event.stopPropagation()" [class.app-disabled]="!!loading">play_arrow</mat-icon></div>            
          </div>
        </div>
    </div>
   -->

  </div>

  </div>

  <p style="font-size: 14px; color: #888; margin-top: 10px;">
    {{ 'settings.tts.text3' | translate }}
  </p>

</div>


<mat-menu #languagesMenu="matMenu" xPosition="after" class="app-languages-menu">
  <ul class="app-4-cols">
    <li class="app-dropdown-item" *ngFor="let lang of languages" (click)="tts.addActiveLanguage(lang)" (keydown.enter)="closeMenu()" mat-menu-item role="button" tabindex="0">
      <div class="app-language" [class.app-disabled]="tts.isLanguageActive(lang)">
        <img src="assets/img/flag_{{lang}}.png">
        <div class="app-language-name">{{ languageService.getLanguageName(lang) }}</div>
      </div>
    </li>
  </ul>
</mat-menu>


<mat-menu #voicesMenu="matMenu" (closed)="stopReading()" xPosition="after" class="app-voices-menu">
  <ul *ngIf="menuLanguage" [class.app-4-cols]="showVoicesInGrid">
    <li class="app-dropdown-item" *ngFor="let voice of voices[menuLanguage.code]" (click)="tts.setLanguageVoice(menuLanguage.code, voice)" (keydown.enter)="closeMenu()" mat-menu-item role="button" tabindex="0">
      <div class="app-voice" [class.app-active]="tts.ttsVoice(menuLanguage.code).code == voice.code">
        <div class="app-voice-name">
          <div class="app-test-voice">
            <mat-icon *ngIf="!isVoiceReading(menuLanguage.code, voice, 'menu')" class="app-play" (click)="$event.stopPropagation(); read(menuLanguage.code, voice, 'menu')">volume_up</mat-icon>
            <mat-icon *ngIf="isVoiceReading(menuLanguage.code, voice, 'menu') && readingVoiceState == 'loading'" class="app-play app-loading" (click)="$event.stopPropagation()">sync</mat-icon>
            <mat-icon *ngIf="isVoiceReading(menuLanguage.code, voice, 'menu') && readingVoiceState == 'reading'" class="app-play app-reading" (click)="$event.stopPropagation(); stopReading()">stop</mat-icon>
          </div>
          <mat-icon class="app-radio">{{ 'radio_button_' + (tts.ttsVoice(menuLanguage.code).code == voice.code ? 'checked' : 'unchecked') }}</mat-icon>
          {{voice.name }}
          <span style="font-size: 12px; color: #aaa; margin-left: 5px;">{{voice.gender == 'male' ? 'M' : 'F'}}</span>
        </div>
      </div>
    </li>
  </ul>
</mat-menu>