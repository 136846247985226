<div class="app-periodical-search-bar-wrapper">
  <div class="app-periodical-search-bar" [class.app-is-query]="query">
    <label for="periodical-search" class="app-hidden-label">{{ 'periodical.search.' + periodicalService.getType() | translate }} ...</label>
    <input id="periodical-search" (keyup)="onKeyUp($event)" type="text" [placeholder]="'periodical.search.' + periodicalService.getType() | translate" [(ngModel)]="query" />
    <button (click)="onMagnifyIconClick()" class="app-button app-button-search" mat-icon-button [attr.aria-label]="'searchbar.search' | translate" [matTooltip]="'searchbar.search' | translate">
      <mat-icon>search</mat-icon>
    </button>
    <button *ngIf="query" (click)="cleanQuery()" class="app-button app-button-close" mat-icon-button [attr.aria-label]="'searchbar.delete_query' | translate" [matTooltip]="'searchbar.delete_query' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
