<h1 *ngIf="periodicalService.fulltext">
  <span>{{ 'search.results' | translate }}: {{ periodicalService.fulltext.getResultsRange() }}</span>
  <span class="app-slash">/</span> 
  <span class="app-all">{{periodicalService.getNumberOfResults()}}</span>
</h1>
<h1 *ngIf="!periodicalService.fulltext">
  <span>{{ 'search.results' | translate }}: </span>
  <span class="app-all">{{periodicalService.getNumberOfResults()}}</span>
</h1>

