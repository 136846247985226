<div class="app-ocr-dialog">

  <div class="app-text">
    <h3 *ngIf="title" style="margin: 0; margin-bottom: 5px;">{{ title }}</h3>
    <div class="app-text-content" [innerHtml]="ocrTxt"></div>
    <label for="ocr-text" class="app-hidden-label">Text</label>
    <textarea id="ocr-text" #ocrInputTarget [value]="copyText" type="text" ></textarea>
    <div class="app-actions">
      <mat-icon tabindex="0" class="app-share-btn" (cbOnSuccess)="onCopied($event)" [ngxClipboard]="ocrInputTarget" [matTooltip]="'common.copy_to_clipboard' | translate">content_copy</mat-icon>
      <mat-spinner *ngIf="loading" [strokeWidth]="2" [diameter]="20"></mat-spinner>
    </div>
  </div>


  <div class="app-citation" *ngIf="citation">
    <div [innerHtml]="citation"></div>
    <label for="ocr-citation" class="app-hidden-label">{{ 'citation-dialog.title' | translate }}</label>
    <input id="ocr-citation" #citationInputTarget [value]="citationTxt" type="text" >
    <div class="app-actions">
      <mat-icon tabindex="0" class="app-share-btn" (cbOnSuccess)="onCopied($event)" [ngxClipboard]="citationInputTarget" [matTooltip]="'common.copy_to_clipboard' | translate">content_copy</mat-icon>
    </div>
  </div>

  <div class="app-dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'common.close' | translate }}</button>
  </div>

</div>