
<header>
  <app-signpost-header></app-signpost-header>
</header>
<div class="sp-content-wrapper">
  <main>
    <app-signpost-libraries></app-signpost-libraries>
  </main>
  <footer>
    <app-signpost-footer></app-signpost-footer>
  </footer>
</div>
