<div class="app-metadata-wrapper" *ngIf="metadata">

  <div class="app-metadata-controls" *ngIf="controlsEnabled">
    
    <!-- <mat-icon tabindex="0" *ngIf="actionAvailable('like')" [class.app-disabled]="!actionEnabled('like')" (click)="onLike()" [matTooltip]="'book_controls.tooltip_share' | translate">favorite</mat-icon> -->
    <mat-icon tabindex="0" *ngIf="actionAvailable('share')" [class.app-disabled]="!actionEnabled('share')" (click)="onShare()" [matTooltip]="'book_controls.tooltip_share' | translate">share</mat-icon>
    <mat-icon tabindex="0" *ngIf="actionAvailable('citation')" [class.app-disabled]="!actionEnabled('citation')" (click)="onShowCitation()" [matTooltip]="'book_controls.tooltip_citation' | translate">format_quote</mat-icon>
    <mat-icon tabindex="0" *ngIf="actionAvailable('metadata')" [class.app-disabled]="!actionEnabled('metadata')" (click)="onShowMetadata()" [matTooltip]="'book_controls.tooltip_metadata' | translate">code</mat-icon>
    <mat-icon tabindex="0" *ngIf="auth.isAdmin()" (click)="openAdminActions()" [matTooltip]="'book_controls.tooltip_edit' | translate">edit</mat-icon>
    <span class="app-dropdown" *ngIf="auth.isLoggedIn() && settings.folders" [matMenuTriggerFor]="favoritesMenu" (click)="openLikeMenu()">
      <mat-icon tabindex="0" [matTooltip]="'folders.like' | translate">favorite</mat-icon>
    </span>
  </div>

  <div class="app-navigation-docs" *ngIf="settings.docsNav && navigationService.hasNav() && controlsEnabled">
    <div class="app-btn app-btn-prev" [class.app-disabled]="!navigationService.hasPrevious()" (click)="navigationService.previous()"><mat-icon>keyboard_double_arrow_left</mat-icon></div>
    <div class="app-btn app-btn-next" [class.app-disabled]="!navigationService.hasNext()" (click)="navigationService.next()"><mat-icon>keyboard_double_arrow_right</mat-icon></div>
  </div>

  <div class="app-metadata-content">

    <ng-container *ngIf="metadata.titles[0]">
      <ng-container *ngIf="metadata.doctype == 'collection'">{{ getCollectionTitle() }}</ng-container>

      <ng-container *ngIf="!expandedTitle && metadata.doctype != 'collection'">
        <h1 *ngIf="metadata.getTitle()" [class.app-cut]="metadata.getTitle().length > 75">
          {{ metadata.getTitle() }}
        </h1>
        <h2>{{metadata.titles[0].subTitle}}</h2>
        <h2>
          <span *ngIf="metadata.titles[0].partNumber">{{ metadata.titles[0].partNumber }}. </span>
          <span>{{ metadata.titles[0].partName }}</span>
        </h2>
        <mat-icon tabindex="0" style="margin-bottom: 0px;" *ngIf="expendableTitle()" [matTooltip]="'filters.btn_show_more' | translate" (click)="toggleExpandedTitle()" class="app-more">more_horiz</mat-icon>
      </ng-container>

      <ng-container *ngIf="expandedTitle && metadata.doctype != 'collection'">
        <ng-container *ngFor="let title of metadata.titles; let i=index">
          <div *ngIf="i == 1">
            <h3>{{ 'metadata.title_other' | translate }}</h3>
          </div>
          <h1 *ngIf="metadata.getTitle()">
            {{ title.mainTitle() }}
          </h1>
          <h2>{{title.subTitle}}</h2>
          <h2>
            <span *ngIf="title.partNumber">{{ title.partNumber }}. </span>
            <span>{{ title.partName }}</span>
          </h2>
        </ng-container>
        <mat-icon tabindex="0" style="margin-bottom: 0px;" [matTooltip]="'filters.btn_show_less' | translate" (click)="toggleExpandedTitle()" class="app-more">more_horiz</mat-icon>
      </ng-container>


      <!-- <ul> -->
        <h2 *ngIf="metadata.hasIdentifier('issn')" class="app-identifier">
          ISSN {{ metadata.identifiers['issn'].join(", ") }}
        </h2>

        <h2 *ngIf="metadata.hasIdentifier('isbn')" class="app-identifier">
          ISBN {{ metadata.identifiers['isbn'].join(", ") }}
        </h2>

        <h2 *ngIf="metadata.hasIdentifier('doi')" class="app-identifier">
          DOI: <a href="{{ metadata.identifiers['doi'][0] }}" target="_blank">{{ metadata.identifiers['doi'][0] }}</a>
        </h2>
      <!-- </ul> -->

      <a *ngIf="settings.originLink && metadata.getOriginUrl()" target="_blank" [href]="metadata.getOriginUrl()" (click)="analytics.sendEvent('metadata', 'link', 'origin');">{{ 'metadata.origin' | translate }}</a>

      <div *ngIf="metadata.doctype == 'convolute'">
        <a [routerLink]="[settings.getRouteFor('periodical'), metadata.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'omnibus list');">
          {{ 'metadata.convolute_part_list' | translate }}
        </a>
      </div>

      <div *ngIf="metadata.currentUnit && metadata.doctype != 'convolute'">
        <a [routerLink]="[settings.getRouteFor('periodical'), metadata.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'unit list');">
          {{ 'metadata.unit_list' | translate }}
        </a>
      </div>
      <div *ngIf="metadata.volume">
        <a [routerLink]="[settings.getRouteFor('periodical'), metadata.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'volume list');">
          {{ 'metadata.volume_list' | translate }} 
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="bookService.source && controlsEnabled">
      <h3>
        {{ 'metadata.sources' | translate }}
      </h3>

      <div class="app-source" [matMenuTriggerFor]="sourcesMenu">
        <img alt="" src='{{ "https://registr.digitalniknihovna.cz/libraries/" + bookService.source + "/logo" }}'>
        <span>{{ 'source.' + bookService.source | translate }}</span>
      </div>
      <mat-menu #sourcesMenu="matMenu" class="app-libraries-menu">
        <ng-template matMenuContent ngFor>
          <div class="app-dropdown-item" *ngFor="let source of bookService.sources">
            <a [routerLink]="[settings.getRouteFor('view'), bookService.uuid]" [queryParams]="{source: source, fulltext: bookService.fulltextQuery }" (click)="analytics.sendEvent('metadata', 'change source', source);">
              <img [alt]="" src='{{ "https://registr.digitalniknihovna.cz/libraries/" + source + "/logo" }}' class="app-library-icon" />
              <div class="app-library-name">{{ 'source.' + source | translate }} </div>
            </a>
          </div>
        </ng-template>
      </mat-menu>
    </ng-container>


    <div *ngIf="!metadata.isPublic || settings.ignorePolicyFlag" class="app-policy">      
      <div *ngIf="metadata.licence" class="app-active-licence">
        <h3>
          {{ 'licence.metadata_licence' | translate }}
        </h3>
        <div tabindex="0" class="app-licence-value" (click)="showLicenceDialog()">
          {{ licences.label(metadata.licence) }}
        </div>
        <div class="app-licence-image" *ngIf="licences.image(metadata.licence)">
          <ng-container *ngIf="licences.web(metadata.licence); else no_link_licence">
            <a style="display:block" target="_blank" [href]="licences.web(metadata.licence)" (click)="analytics.sendEvent('metadata', 'link', 'licence');">
              <img [alt]="licences.label(metadata.licence)" [src]="licences.image(metadata.licence)">
            </a>
          </ng-container>
          <ng-template #no_link_licence>
            <img [alt]="licences.label(metadata.licence)" [src]="licences.image(metadata.licence)">
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="metadata.getPrimaryAuthors().length > 0">
      <h3>{{ 'metadata.author' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let author of metadata.getPrimaryAuthors(); let index=index">
          <li *ngIf="index < 3">
            <a *ngIf="settings.availableFilter('authors')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{authors: author.name }" (click)="analytics.sendEvent('metadata', 'link', 'author');">{{ author.name }}</a>
            <div *ngIf="!settings.availableFilter('authors')">{{ author.name }}</div>
          </li>
        </ng-container>
      </ul>
      <mat-icon tabindex="0" [matTooltip]="'metadata.authors_info' | translate" (click)="onShowAuthors()" class="app-more">more_horiz</mat-icon>
    </div>

    <div *ngIf="metadata.getOtherAuthors().length > 0">
      <h3>{{ 'metadata.author_other' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let author of metadata.getOtherAuthors(); let index=index">
          <li *ngIf="index < 3">
            <a *ngIf="settings.availableFilter('authors')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{authors: author.name }" (click)="analytics.sendEvent('metadata', 'link', 'author');">{{ author.name }}</a>
            <div *ngIf="!settings.availableFilter('authors')">{{ author.name }}</div>
          </li>
        </ng-container>
      </ul>
      <mat-icon tabindex="0" [matTooltip]="'metadata.authors_info' | translate" (click)="onShowAuthors()" class="app-more">more_horiz</mat-icon>
    </div>
    
    <div *ngIf="metadata.volume">
      <h3>{{ 'metadata.volume' | translate }}</h3>
      <ul>
        <li>{{ 'metadata.volume_year' | translate }} <strong>{{ metadata.volume.year }}</strong></li>
        <li>{{ 'metadata.volume' | translate }} <strong>{{ metadata.volume.number }}</strong></li>
        <li *ngIf="metadata.currentIssue && (metadata.nextIssue || metadata.previousIssue)">
          <a [routerLink]="[settings.getRouteFor('periodical'), metadata.volume.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'issue list');">
            {{ 'metadata.issue_list' | translate }}
          </a>
        </li>
      </ul>
      <div *ngIf="metadata.volumeMetadata" >
        <app-metadata [metadata]="metadata.volumeMetadata" class="app-nested" [showControls]="false"></app-metadata>
      </div>

      <div *ngIf="metadata.previousVolume">
        <a [routerLink]="[settings.getRouteFor('periodical'), metadata.previousVolume.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'previous volume');">
          {{ 'metadata.previous_volume' | translate }}{{ metadata.previousVolume.prettyName() }}
        </a>
      </div>

      <div *ngIf="metadata.nextVolume">
        <a [routerLink]="[settings.getRouteFor('periodical'), metadata.nextVolume.uuid]" (click)="analytics.sendEvent('metadata', 'link', 'next volume');">
          {{ 'metadata.next_volume' | translate }} {{ metadata.nextVolume.prettyName() }}
        </a>
      </div>
    </div>

    <div *ngIf="metadata.currentIssue">
      <h3>{{ 'metadata.issue' | translate }}</h3>
      <ul>
        <li>{{ 'metadata.issue_date' | translate }} <strong>{{ metadata.currentIssue.getDate() }}</strong></li>
        <li>{{ 'metadata.issue' | translate }} <strong>{{ metadata.currentIssue.getPart() }}</strong></li>
      </ul>
      <div *ngIf="metadata.previousIssue">
        <a [routerLink]="[settings.getRouteFor('view'), metadata.previousIssue.uuid]" [queryParams]="{source: bookService.source }" (click)="analytics.sendEvent('metadata', 'link', 'previous issue');">
          {{ 'metadata.previous_issue' | translate }} {{ metadata.previousIssue.prettyName() }}
        </a>
      </div>

      <div *ngIf="metadata.nextIssue">
        <a [routerLink]="[settings.getRouteFor('view'), metadata.nextIssue.uuid]" [queryParams]="{source: bookService.source }" (click)="analytics.sendEvent('metadata', 'link', 'next issue');">
          {{ 'metadata.next_issue' | translate }} {{ metadata.nextIssue.prettyName() }}
        </a>
      </div>

      <div *ngIf="metadata.currentIssue.metadata" >
        <app-metadata [metadata]="metadata.currentIssue.metadata" class="app-nested" [showControls]="false"></app-metadata>
      </div>
    </div>

    <div *ngIf="metadata.currentUnit">
      <h3>{{ 'metadata.unit' | translate }}</h3>
      <div *ngIf="metadata.currentUnit.metadata" >
        <app-metadata [metadata]="metadata.currentUnit.metadata" class="app-nested" [showControls]="false"></app-metadata>
      </div>
      <div *ngIf="metadata.previousUnit">
        <a [routerLink]="[settings.getRouteFor('view'), metadata.previousUnit.uuid]" class="single-line" (click)="analytics.sendEvent('metadata', 'link', 'previous unit');">
          {{ 'metadata.previous_unit' | translate }} {{ metadata.previousUnit.title}}
        </a>
      </div>
      <div *ngIf="metadata.nextUnit">
        <a [routerLink]="[settings.getRouteFor('view'), metadata.nextUnit.uuid]" class="single-line" (click)="analytics.sendEvent('metadata', 'link', 'next unit');">
          {{ 'metadata.next_unit' | translate }} {{ metadata.nextUnit.title }}
        </a>
      </div>
    </div>


    <div *ngIf="metadata.article && metadata.article.metadata" >
      <h3>{{ 'metadata.article' | translate }}</h3>
      <app-metadata [metadata]="metadata.article.metadata" class="app-nested" [showControls]="false"></app-metadata>
    </div>



    <div *ngIf="metadata.internalPart && metadata.internalPart.metadata" >
      <h3>{{ 'model.internalpart' | translate }}</h3>
      <app-metadata [metadata]="metadata.internalPart.metadata" class="app-nested" [showControls]="false"></app-metadata>
    </div>



    <div *ngIf="metadata.extraParentMetadata">
      <h3>{{ 'model.' + metadata.extraParentMetadata.doctype | translate }}</h3>
      <app-metadata [metadata]="metadata.extraParentMetadata" class="app-nested" [showControls]="false"></app-metadata>
    </div>

    <div *ngIf="metadata.donators">
      <h3>{{ 'metadata.donator' | translate }}</h3>
      <ul>
        <li *ngFor="let donator of metadata.donators">
          <a style="display:block" target="_blank" href="{{ 'donator.' + donator + '.url' | translate }}" (click)="analytics.sendEvent('metadata', 'link', 'donator');">
            <img src="assets/img/donator_{{donator}}.png" >
          </a>
        </li>
      </ul>
    </div>


    <div *ngIf="metadata.publishers.length > 0">
      <h3>{{ 'metadata.publisher' | translate }}</h3>
      <ul>
        <li *ngFor="let publisher of metadata.publishers; first as first">{{ publisher.fullDetail() }}</li>
      </ul>
    </div>

    <div *ngIf="metadata.doctype && metadata.doctype != 'soundunit'">
      <h3>{{ 'metadata.doctype' | translate }}</h3>
      <ul>
        <li>
          <a *ngIf="settings.availableFilter('doctypes') && metadata.doctype !== 'monographbundle'" [routerLink]="settings.getRouteFor('search')" [queryParams]="{doctypes: metadata.doctype }" (click)="analytics.sendEvent('metadata', 'link', 'doctype');">{{ 'model.' + metadata.doctype | translate }}</a>
          <div *ngIf="!settings.availableFilter('doctypes') || metadata.doctype === 'monographbundle'">{{ 'model.' + metadata.doctype | translate }}</div>
        </li>
      </ul>
    </div>

    <div *ngIf="metadata.inCollections.length > 0">
      <h3>{{ 'metadata.in_collection' | translate }}</h3>
      <ul>
        <li *ngFor="let collection of metadata.inCollections">
          <a [routerLink]="[settings.getRouteFor('collection'), collection.uuid]">
              {{ collection.name }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="metadata.keywords.length > 0">
      <h3>{{ 'metadata.keywords' | translate }}</h3>
      <ul>
        <li *ngFor="let keyword of metadata.keywords">
          <a *ngIf="settings.availableFilter('keywords')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{keywords: keyword }" (click)="analytics.sendEvent('metadata', 'link', 'keyword');">{{ keyword | appUpcase }}</a>
          <div *ngIf="!settings.availableFilter('keywords')">{{ keyword }}</div>
        </li>
      </ul>
    </div>

    <div *ngIf="metadata.genres.length > 0">
      <h3>{{ 'metadata.genres' | translate }}</h3>
      <ul>
        <li *ngFor="let genre of metadata.genres">
          <a *ngIf="settings.availableFilter('genres')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{genres: genre }" (click)="analytics.sendEvent('metadata', 'link', 'genre');">{{ genre | appUpcase}}</a>
          <div *ngIf="!settings.availableFilter('genres')">{{ genre }}</div>
        </li>
      </ul>
    </div>

    <div *ngIf="metadata.geonames.length > 0">
      <h3>{{ 'metadata.geonames' | translate }}</h3>
      <ul>
        <li *ngFor="let geoname of metadata.geonames">
          <a *ngIf="settings.availableFilter('geonames')"  [routerLink]="settings.getRouteFor('search')" [queryParams]="{geonames: geoname }" (click)="analytics.sendEvent('metadata', 'link', 'geoname');">{{ geoname | appUpcase}}</a>
          <div *ngIf="!settings.availableFilter('geonames')">{{ geoname }}</div>
        </li>
      </ul>
    </div>

    <div *ngIf="metadata.languages.length > 0">
      <h3>{{ 'metadata.languages' | translate }}</h3>
      <ul>
        <li *ngFor="let language of metadata.languages">
          <a *ngIf="settings.availableFilter('languages')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{languages: language }" (click)="analytics.sendEvent('metadata', 'link', 'language');">{{ 'language.' + language | translate | appUpcase }}</a>
          <div *ngIf="!settings.availableFilter('languages')">{{ 'language.' + language | translate | appUpcase }}</div>
        </li>
      </ul>
    </div>
    
    <div *ngIf="metadata.locations.length > 0">
      <h3>{{ 'metadata.location' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let location of metadata.locations">
          <li *ngIf="location.physicalLocation">
            <a *ngIf="settings.availableFilter('locations')" [routerLink]="settings.getRouteFor('search')" [queryParams]="{locations: location.physicalLocation }" (click)="analytics.sendEvent('metadata', 'link', 'location');">{{ 'sigla.' + location.physicalLocation | translate | appRemovePrefix:'sigla.' }}</a>
            <div *ngIf="!settings.availableFilter('locations')">{{ 'sigla.' + location.physicalLocation | translate | appRemovePrefix:'sigla.' }}</div>
          </li>
          <li *ngIf="location.shelfLocator" translate [translateParams]="{ shelf_locator: location.shelfLocator }">metadata.shelf_locator</li>
        </ng-container>
      </ul>
    </div>

    <div *ngIf="metadata.physicalDescriptions.length > 0">
      <h3>{{ 'metadata.physical_description' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let desc of metadata.physicalDescriptions">
          <li *ngIf="desc.extent">{{ 'metadata.extent' | translate }}: {{ desc.extent }}</li>
          <li *ngIf="desc.note">{{ desc.note }}</li>
        </ng-container>
      </ul>
    </div>

    <div *ngIf="metadata.cartographicData.length > 0">
      <h3>{{ 'metadata.cartographic_data' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let cd of metadata.cartographicData">
          <li *ngIf="cd.scale">{{ cd.scale }}</li>
          <!-- <div class="app-value" *ngIf="cd.coordinates">
            <div *ngIf="displayCoordinates(cd.coordinates).length < 2">
              {{ displayCoordinates(cd.coordinates) }}
            </div>
            <div *ngIf="displayCoordinates(cd.coordinates).length > 1">
              <div>{{ displayCoordinates(cd.coordinates)[0] }}</div>
              <div>{{ displayCoordinates(cd.coordinates)[1] }}</div>
            </div>
          </div> -->
          <li *ngIf="cd.coordinates">{{ cd.coordinates }}</li>
          <!-- <a *ngIf="cd.coordinates" [routerLink]="settings.getRouteFor('search')" [queryParams]="{north: parseBoundingBox(cd.coordinates).n, south: parseBoundingBox(cd.coordinates).s, west: parseBoundingBox(cd.coordinates).w, east: parseBoundingBox(cd.coordinates).e}" (click)="analytics.sendEvent('metadata', 'link', 'location');">{{ 'metadata.display_in_map' | translate }}</a> -->
        </ng-container>
      </ul>
    </div>

    <div *ngIf="metadata.extent">
      <h3>{{ 'metadata.extent' | translate }}</h3>
      <ul>
        <li>{{ metadata.extent }}</li>
      </ul>
    </div>

    <div *ngIf="metadata.abstracts.length > 0 && metadata.doctype != 'collection'">
      <h3>{{ 'metadata.abstract' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let abstract of metadata.abstracts; let i = index">
          <li *ngIf="abstract.length > 115 && !expand['abstract' + i]" >{{ abstract.substring(0, 110) + "..." }}</li>
          <li *ngIf="!(abstract.length > 115 && !expand['abstract' + i])" >{{ abstract }}</li>
          <mat-icon tabindex="0" *ngIf="abstract.length > 115" (click)="expand['abstract' + i] = !expand['abstract' + i]" class="app-more">more_horiz</mat-icon>
        </ng-container>
      </ul>
    </div>

    <div *ngIf="metadata.contents.length > 0">
      <h3>{{ 'metadata.content' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let content of metadata.contents; let i = index">
          <li *ngIf="i < 5 || contentsExpanded" class="app-list-mb">
            {{ content }}
          </li>
        </ng-container>
      </ul>
      <mat-icon tabindex="0" style="margin-top: 5px;" *ngIf="metadata.contents.length > 5" [matTooltip]="'filters.btn_show_' + (contentsExpanded ? 'less' : 'more') | translate" (click)="contentsExpanded = !contentsExpanded" class="app-more">more_horiz</mat-icon>
    </div>

    <div *ngIf="metadata.notes.length > 0 && metadata.doctype != 'collection'">
      <h3>{{ 'metadata.notes' | translate }}</h3>
      <ul>
        <ng-container *ngFor="let note of metadata.notes; let i = index">
          <li *ngIf="i < 5 || notesExpanded" class="app-list-mb">
            <div [innerHTML]="toHtml(note) | safeHtml"></div>
          </li>
        </ng-container>
      </ul>
      <mat-icon tabindex="0" style="margin-top: 5px" *ngIf="metadata.notes.length > 5" [matTooltip]="'filters.btn_show_' + (notesExpanded ? 'less' : 'more') | translate" (click)="notesExpanded = !notesExpanded" class="app-more">more_horiz</mat-icon>
    </div>
    

  <div *ngIf="metadata.reviews && metadata.reviews.length > 0" >
    <h3>{{ 'metadata.review_of' | translate }}</h3>
    <ng-container *ngFor="let review of metadata.reviews">
      <app-metadata [metadata]="review" class="app-nested" [showControls]="false"></app-metadata>
    </ng-container>
  </div>


  <div *ngIf="metadata.hasIdentifier('urnnbn')">
    <h3>URN:NBN</h3>
    <ul>
      <li>
        {{ metadata.identifiers['urnnbn'].join(", ") }}
      </li>
    </ul>
  </div>

  <div *ngIf="metadata.hasIdentifier('ccnb')">
    <h3>čČNB</h3>
    <ul>
      <li>
        {{ metadata.identifiers['ccnb'].join(", ") }}
      </li>
    </ul>
  </div>

  <div *ngIf="metadata.activePage">
    <h3>{{ 'metadata.page' | translate }}</h3>
    <ul>
      <li>{{ metadata.activePage.number }} {{ metadata.activePage.title }}
          <span *ngIf="metadata.activePageRight"> - {{ metadata.activePageRight.number }} {{ metadata.activePageRight.title }}</span>
      </li>
    </ul>
  </div>



  </div>
</div>

<mat-menu #favoritesMenu="matMenu" xPosition="before" class="app-dropdown-menu">
  <div class="app-dropdown-header">
    <div class="app-doctypes-label-add" (click)="$event.stopPropagation()">{{'folders.add' | translate}}</div>
  
    <!-- <div class="app-dropdown-doctypes">
      <div *ngFor="let item of items" class="app-doctype-item" [class.active]="selectedType === item.type" (click)="changeUuid(item.uuid, item.type); $event.stopPropagation()">{{ 'model_4p.' + item.type | translate}}</div>
    </div> -->
  
    <mat-form-field class="app-mat-form-field" (click)="$event.stopPropagation()">
      <mat-select [(value)]="selectedItem" (click)="$event.stopPropagation()" panelClass="app-select">
        <mat-option [value]="item" *ngFor="let item of items" (click)="changeUuid(item.uuid, item.type)">{{ 'model_4p.' + item.type | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <div class="app-doctypes-label-to-folder" (click)="$event.stopPropagation()">{{'folders.to_folder' | translate}}</div>
  </div>
  <ng-template matMenuContent>
    <div mat-menu-item tabindex="0" class="app-dropdown-item" *ngFor="let folder of folderService.getMyFolders()" (click)="onLike(folder, selectedUuid)">
      {{ folder.name }}
    </div>
    <div class="app-dropdown-input">
      <input #matInput [placeholder]="'folders.new_list' | translate" (click)="$event.stopPropagation()" (keyup.enter)="onNewFolder(matInput.value)" (keydown)="$event.stopPropagation()">
  </div>
  </ng-template>
</mat-menu>