<app-periodical-count></app-periodical-count>
<app-periodical-search></app-periodical-search>

<mat-accordion [displayMode]="'flat'" [multi]="true" class="app-filters-wrapper">
  <!-- FILTR FOLDER -->
  <mat-expansion-panel *ngIf="periodicalService.query.folder" class="app-filter-panel" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Seznam   
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="app-filter-items">
      <div class="app-filter-item" tabindex="0" [class.app-selected]="periodicalService.query.folder.name" (click)="periodicalService.removeFolder()">
        <mat-icon class="app-close">close</mat-icon>
        {{ periodicalService.folderName }}
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="filters.indexOf('accessibility') > -1" class="app-filter-panel" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'search.accessibility.label' | translate }}   
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="app-filter-items">
      <div class="app-filter-item" tabindex="0" [class.app-selected]="periodicalService.query && periodicalService.query.accessibility === 'public'"
      (click)="periodicalService.setAccessibility('public');analytics.sendEvent('periodical', 'accessibility', 'public');">
        {{'search.accessibility.public' | translate }}
      </div>
      <div class="app-filter-item" tabindex="0" [class.app-selected]="periodicalService.query && periodicalService.query.accessibility === 'private'"
      (click)="periodicalService.setAccessibility('private');analytics.sendEvent('periodical', 'accessibility', 'private');">
        {{'search.accessibility.private' | translate }}
      </div>
      <div class="app-filter-item" tabindex="0" [class.app-selected]="periodicalService.query && periodicalService.query.accessibility === 'all'"
      (click)="periodicalService.setAccessibility('all');analytics.sendEvent('periodical', 'accessibility', 'all');">
        {{'search.accessibility.all' | translate }}
      </div>
    </div>
  </mat-expansion-panel>


    <mat-expansion-panel *ngIf="periodicalService.document && filters.indexOf('sources') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.sources' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="app-filter-items">

        <div  [class.app-selected]="!periodicalService.query.source"
              tabindex="0"
              (click)="periodicalService.setSource();analytics.sendEvent('periodical', 'source', 'all')"
              class="app-filter-item">
              {{'search.accessibility.all' | translate }}
        </div>

        <ng-container *ngFor="let source of periodicalService.document.sources; let i = index">
          <div *ngIf="i < 5 || sourcesExpanded"
                [class.app-selected]="source == periodicalService.query.source"
                tabindex="0"
                (click)="periodicalService.setSource(source);analytics.sendEvent('periodical', 'source', source)"
                class="app-filter-item">
                {{'source.' + source | translate | appRemovePrefix:'source.' }}
          </div>
        </ng-container>
        <div *ngIf="periodicalService.document.sources.length > 5"
            (click)="sourcesExpanded = !sourcesExpanded"
            tabindex="0"
             class="app-filter-item app-expand">
            {{ 'filters.btn_show_' + (sourcesExpanded ? 'less' : 'more') | translate }}  
        </div>
      </div>
    </mat-expansion-panel>


  <mat-expansion-panel class="app-filter-panel" [expanded]="true" *ngIf="periodicalService.isPeriodical()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'search.year-range.label' | translate }}   
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="app-filter-items">
      <div class="year-range-wrapper">
        <fieldset>
          <legend class="app-visualy-hidden">{{ 'search.year-range.label' | translate }}</legend>
          <div class="year-range">
            <span>{{ 'search.year-range.from' | translate }}</span>
            <input [(ngModel)]="yearFrom" (change)="onYearFromValueChanged()" type="number">
            <span>{{ 'search.year-range.to' | translate }}</span>
            <input [(ngModel)]="yearTo" (change)="onYearToValueChanged()" type="number">
          </div>
        </fieldset>
        <button (click)="applyYearRange()" mat-flat-button color="primary">{{ 'search.year-range.apply' | translate }}</button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
