
<div class="app-share app-dialog">


    <!-- <div class="app-tabs" *ngIf="selection">
      <div class="app-dialog-title">{{ 'share-dialog.title' | translate }}</div>
      <div *ngFor="let item of items" class="app-tab" [class.active]="selection.type === item.type"
        tabindex="0"
        (click)="changeTab(item)">
        {{ 'model_4p.' + item.type | translate }}
      </div>
    </div> -->
  
    <div class="app-dialog-content">
  
      <div class="app-label">{{ 'share-dialog.link' | translate }}</div>
      <div class="app-link">
        <label for="share-input" class="app-hidden-label">URL</label>
        <input id="share-input" #inputTarget readonly [(ngModel)]="link" type="text">
        <mat-icon tabindex="0" (cbOnSuccess)="onCopied($event)" [ngxClipboard]="inputTarget" [matTooltip]="'common.copy_to_clipboard' | translate">content_copy</mat-icon>
      </div>
  
  
      <div class="app-label">{{ 'share-dialog.social' | translate }}</div>
  
      <div class="app-social">
        <img alt="Facebook" tabindex="0" src="/assets/img/ic_share_facebook.png" (click)="share('facebook')">
        <img alt="Twitter" tabindex="0" src="/assets/img/ic_share_twitter.png" (click)="share('twitter')">
      </div>
  
    </div>
        
  
    <div class="app-dialog-actions">
      <button mat-button (click)="onCancel()">{{ 'common.close' | translate }}</button>
    </div>
  
  </div>
  
  
  
  
