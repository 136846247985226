<div tabindex="0" role="button" id="page-id-{{page.uuid}}" (click)="onPageClicked()" class="app-snippet" [class.active]="page.selected">
  <div class="app-snippet-content">
    <div class="app-snippet-thumb-wrapper">
      <div class="app-snippet-thumb" [scrollTarget]="container" [lazyLoad]="page.thumb">
        <div *ngIf="lock" class="app-lock {{ lock.class }}">
          <mat-icon>{{ lock.icon }}</mat-icon>
        </div>    
      </div>
    </div>
    <div class="app-snippet-right">
      <div class="app-snippet-title">
        {{ page.number }}
      </div>
      <div class="app-snippet-ocr" [innerHTML]="page.snippet"></div>
    </div>
  </div>
</div>