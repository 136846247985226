<div class="app-view-controls">


  <mat-icon 
    *ngIf="showPrevNextPage()" 
    tabindex="0" 
    (click)="goToPrevious()" 
    [matTooltip]="'view_controls.tooltip_page_left' | translate" 
    [class.app-inactive]="!hasPrevious()">
    chevron_left
  </mat-icon>

  <mat-icon (click)="zoomIn()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_zoom_in' | translate" 
            *ngIf="showZoom()">
            add_circle_outline
  </mat-icon>

  <mat-icon (click)="zoomOut()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_zoom_out' | translate" 
            *ngIf="showZoom()">
            remove_circle_outline
  </mat-icon>

  <mat-icon (click)="fitToScreen()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_fit_to_screen' | translate"
            *ngIf="showFitToScreen()">
            fit_screen
  </mat-icon>


  <mat-icon (click)="enterFullscreen()"
            [matTooltip]="'view_controls.tooltip_enter_fullscreen' | translate" 
            tabindex="0"
            *ngIf="showEnterFullscreen()">
            fullscreen
  </mat-icon>

  <mat-icon (click)="exitFullscreen()"
            tabindex="0"
            [matTooltip]="'view_controls.tooltip_exit_fullscreen' | translate" 
            *ngIf="showExitFullscreen()">
            fullscreen_exit
  </mat-icon>


  <mat-icon (click)="rotate()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_rotate_right' | translate" 
            *ngIf="showRotation()">
            rotate_right
  </mat-icon>

  <mat-icon (click)="doublePageOff()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_doublepage_off' | translate" 
            *ngIf="showDoublePageOff()">
            auto_stories
  </mat-icon>

  <mat-icon (click)="doublePageOn()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_doublepage_on' | translate" 
            *ngIf="showDoublePageOn()">
            auto_stories
  </mat-icon>

  <mat-icon (click)="toggleViewerMode()"
    tabindex="0"
    class="hide-on-small"
    [matTooltip]="'Textový režim'" 
    *ngIf="showViewerModeToggle()">
    view_headline
  </mat-icon>

  <mat-icon (click)="controlsService.toggleLock();analytics.sendEvent('viewer', 'controls', 'lock')"
  tabindex="0"
  class="hide-on-small"
  [matTooltip]="'view_controls.tooltip_zoom_lock_on' | translate" 
  *ngIf="showLock()">
  lock_open
</mat-icon>

<mat-icon (click)="controlsService.toggleLock();analytics.sendEvent('viewer', 'controls', 'unlock')"
  tabindex="0"
  class="hide-on-small"
  [matTooltip]="'view_controls.tooltip_zoom_lock_off' | translate" 
  *ngIf="showUnlock()">
  lock
</mat-icon>

  <!-- <mat-icon (click)="controlsService.selectText();analytics.sendEvent('viewer', 'controls', 'select text')"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_select_text' | translate"
            [class.app-inactive]="!selectTextEnabled()" 
            *ngIf="showSelectText()">
            title
  </mat-icon> -->

  <!-- <mat-icon (click)="onCropImageClicked()"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.' + (bookService.showGeoreference ? 'tooltip_cutline' : 'tooltip_crop_image') | translate" 
            [class.app-inactive]="!imageCropEnabled()"
            *ngIf="showImageCrop()">
            crop
  </mat-icon> -->
  
 <mat-icon (click)="controlsService.cropMap();analytics.sendEvent('viewer', 'controls', 'map crop')"
            tabindex="0"
            class="hide-on-small"
            [matTooltip]="'view_controls.tooltip_cutline' | translate" 
            *ngIf="showCropMap()">
            crop
  </mat-icon>

  <!-- <mat-icon 
    tabindex="0"
    class="hide-on-small"
    (click)="enterSelectionMode()"
    [matTooltip]="'Vybrat oblast'"
    *ngIf="showSelectionMode()">
    crop
  </mat-icon> -->

  <mat-icon (click)="showGeoMap()"
            [matTooltip]="'view_controls.tooltip_showgeoreferenced' | translate" 
            *ngIf="bookService.hasGeoreference && !bookService.showGeoreference">
            map
  </mat-icon>

  <mat-icon (click)="hideGeoMap()"
            [matTooltip]="'view_controls.tooltip_hidegeoreferenced' | translate" 
            *ngIf="bookService.hasGeoreference && bookService.showGeoreference">
            image
  </mat-icon>

  <mat-slider *ngIf="bookService.hasGeoreference && bookService.showGeoreference" 
            min="0" max="1" step=".1" [(ngModel)]="controlsService.geoLayerOpacity"
             [matTooltip]="'view_controls.tooltip_opacity' | translate"
            (change)="onChangeOpacity($event)">
  </mat-slider>


  <mat-icon 
  *ngIf="showPrevNextPage()" 
  tabindex="0" (click)="goToNext()" 
  [matTooltip]="'view_controls.tooltip_page_right' | translate" 
  [class.app-inactive]="!hasNext()">
    chevron_right
</mat-icon>


</div>
