<div class="app-folder-container" *ngIf="folder">
  <!-- TOOLBAR -->
    <div class="app-folder-toolbar">
        <div class="app-folder-toolbar-title">{{folder.name}}</div>
        <div class="app-folder-toolbar-actions">
            <!-- ADMIN ACTIONS -->
            <div tabindex="0" *ngIf="user === 'owner' && folderSelection" (click)="openAdminActions()" class="app-view-button" [class.app-active]="true" matTooltip="{{ 'folders.tooltips.delete_selected' | translate }}"><mat-icon>delete_sweep</mat-icon></div>
            <div tabindex="0" *ngIf="user === 'owner' && folderSelection" (click)="toggleAllSelected()" class="app-view-button" [class.app-active]="true" matTooltip="{{ 'folders.tooltips.select_all' | translate }}"><mat-icon>done_all</mat-icon></div>
            <div tabindex="0" *ngIf="user === 'owner' && !folderSelection" (click)="this.toggleFolderSelection()" class="app-view-button" [class.app-active]="folderSelection"  matTooltip="{{ 'folders.tooltips.edit_all' | translate }}" style="margin-right: 16px;">
              <mat-icon>check</mat-icon>
            </div>
            <div tabindex="0" *ngIf="user === 'owner' && folderSelection" (click)="this.toggleFolderSelection()" class="app-view-button" [class.app-active]="folderSelection"  matTooltip="{{ 'folders.tooltips.edit_all_end' | translate }}" style="margin-right: -10px;">
              <mat-icon>clear</mat-icon>
            </div>
            <!-- ADD TO FOLDER BY UUID -->
            <div tabindex="0" *ngIf="user === 'owner' && devMode" (click)="openAddItemToFolderDialog(folder.uuid)" class="app-view-button" matTooltip="{{ 'folders.tooltips.add' | translate }}"><mat-icon>add</mat-icon></div>
            <!-- EDIT / DELETE / SHARE -->
            <div tabindex="0" *ngIf="user === 'owner' && !folderSelection" (click)="openEditFolderDialog(folder.uuid)" class="app-view-button" matTooltip="{{ 'folders.tooltips.edit_title' | translate }}"><mat-icon>drive_file_rename_outline</mat-icon></div>
            <div tabindex="0" *ngIf="user === 'owner' && !folderSelection" (click)="openDeleteFolderDialog(folder.uuid)" class="app-view-button" matTooltip="{{ 'folders.tooltips.delete' | translate }}"><mat-icon>delete</mat-icon></div>
            <div tabindex="0" *ngIf="user === 'owner' && !folderSelection" (click)="openShareFolderDialog(folder.uuid)" class="app-view-button" matTooltip="{{ 'folders.tooltips.share' | translate }}"><mat-icon>share</mat-icon></div>
            <!-- CSV -->
            <div tabindex="0" *ngIf="!folderSelection" [matTooltip]="'toolbar.tooltip_download_csv' | translate"  (click)="downloadCsv()" class="app-view-button">
              <mat-icon>file_download</mat-icon>
            </div>
            <!-- FOLLOW / UNFOLLOW -->
            <div tabindex="0" *ngIf="user === 'follower'" (click)="openUnfollowFolderDialog(folder)" class="app-view-button" matTooltip="{{ 'folders.tooltips.remove_sharing' | translate }}"><mat-icon>delete</mat-icon></div>
            <div tabindex="0" *ngIf="(authService.user.authenticated && user !== 'follower' && user !== 'owner')" (click)="openFollowFolderDialog(folder)" class="app-view-button" matTooltip="{{ 'folders.tooltips.add_sharing' | translate }}"><mat-icon>add</mat-icon></div>
            <div tabindex="0" *ngIf="!authService.user.authenticated" (click)="openBasicDialog()" class="app-view-button" matTooltip="{{ 'folders.tooltips.add_sharing' | translate }}"><mat-icon>add</mat-icon></div>
            <!-- ORDERING -->
            <div class="app-ordering" *ngIf="!folderSelection">
                <span class="app-hide-on-med app-label">{{ 'search.ordering.label' | translate }}: </span>
                <span tabindex="0" class="app-dropdown" [matMenuTriggerFor]="orderingMenu">
                  <span>{{ 'search.ordering.' + ordering | translate }}</span>
                  <mat-icon>arrow_drop_down</mat-icon>
                </span>
            </div>
        </div>
    </div>

<!-- CARDS -->
    <div class="app-cards-wrapper" *ngIf="user === 'owner'">
        <app-document-card *ngFor="let item of changeOrdering(folder.items, ordering)" [selectable]="folderSelection" [in]="'folder-owner'" [item]="item" [display]="'grid'" [folder]="folder"></app-document-card>
    </div>
    <div class="app-cards-wrapper" *ngIf="user === 'follower'">
        <app-document-card *ngFor="let item of changeOrdering(folder.items, ordering)" [selectable]="folderSelection" [in]="'folder-follower'" [item]="item" [display]="'grid'" [folder]="folder"></app-document-card>
    </div>
    <div class="app-cards-wrapper" *ngIf="!user">
        <app-document-card *ngFor="let item of changeOrdering(folder.items, ordering)" [selectable]="folderSelection" [in]="'folder-follower'" [item]="item" [display]="'grid'" [folder]="folder"></app-document-card>
    </div>
</div>

<!-- MAT MENU -->
<mat-menu #orderingMenu="matMenu" xPosition="after" class="app-ordering-menu app-dropdown-menu">
    <ng-template matMenuContent>
      <div mat-menu-item tabindex="0" class="app-dropdown-item" (click)="changeOrdering(folder.items, 'alphabetical')">
        {{ 'search.ordering.alphabetical' | translate }}
      </div>
      <div mat-menu-item tabindex="0" class="app-dropdown-item" (click)="changeOrdering(folder.items, 'latest')">
        {{ 'search.ordering.latest' | translate }}
      </div>
      <div mat-menu-item tabindex="0" class="app-dropdown-item" (click)="changeOrdering(folder.items, 'earliest')">
        {{ 'search.ordering.earliest' | translate }}
      </div>
    </ng-template>
</mat-menu>

