<div class="app-citation app-dialog">

  <div class="app-tabs" *ngIf="selection">
    <div class="app-dialog-title">{{ 'citation-dialog.title' | translate }} <span class="app-citation-spec">(ČSN ISO 690)</span></div>
    <div *ngFor="let item of items" class="app-tab" [class.active]="selection.type === item.type"
      tabindex="0"
      (click)="changeTab(item)">
      {{ 'model_2p.' + item.type | translate }}
    </div>
  </div>

  <div class="app-dialog-content" *ngIf="selection">
    <ng-container *ngIf="selection.citation">
      <div [innerHtml]="selection.citation"></div>
      <label for="citation-input" class="app-hidden-label">{{ 'citation-dialog.title' | translate }}</label>
      <input id="citation-input" #inputTarget [value]="selection.citationTxt" type="text" >
      <mat-icon tabindex="0" class="app-share-btn" (cbOnSuccess)="onCopied($event)" [ngxClipboard]="inputTarget" [matTooltip]="'common.copy_to_clipboard' | translate">content_copy</mat-icon>
    </ng-container>

    <mat-spinner *ngIf="!selection.citation"
          [strokeWidth]="2" 
          [diameter]="20">
    </mat-spinner>
  </div>
      
  <div class="app-dialog-actions">
    <button mat-flat-button (click)="onCancel()">{{ 'common.close' | translate }}</button>
  </div>

</div>



