<div class="app-login-wrapper">
  <div class="app-login-card">

    <div class="app-title">Přihlašte se</div>
    
    <div class="app-label">Jméno</div>
    <input [(ngModel)]="username" placeholder="Zadejte uživatelské jméno" type="text">

    <div class="app-label">Zadejte heslo</div>
    <input [(ngModel)]="password" placeholder="Zadejte heslo" type="password">

    <div *ngIf="state === 'failure'" class="app-error">{{ message }}</div>
    
    <button (click)="login()" mat-flat-button color="primary">Přihlásit</button>
    
  </div>
</div>