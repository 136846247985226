<div class="app-pdf app-dialog">

  <div class="app-pages">
    <div tabindex="0" *ngFor="let page of pages" (click)="onPageSelected(page, $event)" class="app-page" [class.active]="selection[page.uuid]">
      <div class="app-page-thumb" [style.background-image]="thumb(page)"></div>
      <div class="app-page-title">{{page.number}}</div>
      <mat-icon>check</mat-icon>
    </div>
  </div>

  <div class="app-dialog-actions">
    <mat-spinner *ngIf="inProgress"
            [strokeWidth]="2" 
            [diameter]="28">
    </mat-spinner>
    <div class="app-messages" *ngIf="!inProgress">
      <div class="app-info" [class.app-warn]="selectedPages > maxPageCount">
        <ng-container *ngIf="selectedPages > 0">
          {{ 'pdf-dialog.selection1' | appPlural: selectedPages }}
        <strong>{{ selectedPages }}</strong>
          {{ 'pdf-dialog.selection2' | appPlural: selectedPages }}
        (limit <strong>{{ maxPageCount }}</strong>)
      </ng-container>
      <ng-container *ngIf="selectedPages == 0">
        {{ 'pdf-dialog.selection' | translate }}
      </ng-container>
      <ng-container *ngIf="selectedPages < pageCount">
        | <span tabindex="0" (click)="selectAll()" class="app-btn">{{ 'pdf-dialog.select_all' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="selectedPages > 0">
        | <span tabindex="0" (click)="deselectAll()"  class="app-btn">{{ 'pdf-dialog.deselect_all' | translate }}</span>
      </ng-container>
      </div>
      <div class="app-tip" *ngIf="!errorId">{{ 'pdf-dialog.tip_shift' | translate }}</div>
      <div class="app-error" *ngIf="errorId">{{ 'pdf-dialog.' + errorId | translate }}</div>

    </div>
    <div class="app-progress" *ngIf="inProgress">
      {{ 'pdf-dialog.warning_generate' | translate }}
    </div>
    <button mat-flat-button color="primary" (click)="action()" [disabled]="inProgress || selectedPages > maxPageCount || selectedPages == 0">{{ 'pdf-dialog.action_' + type | translate }}</button>
    <button mat-button (click)="onCancel()">{{ 'common.cancel' | translate }}</button>
  </div>

</div>
