<div class="app-navbar">
  <div *ngIf="!state.atHome()" class="col app-col-left" [class.app-col-hidden]="mobileSearchBarExpanded">
    <a tabindex="0" class="app-arrow-left" (click)="goBack()">
      <mat-icon [matTooltip]="'navbar.back' | translate" matTooltipPosition="above" aria-hidden="true">arrow_back</mat-icon>
      <span class="app-visualy-hidden">{{ 'navbar.back' | translate }}</span>
    </a>
    <app-logo></app-logo>
  </div>
  <div *ngIf="state.atHome() && appSettings.landingPage" class="sp-header-logo" [matTooltip]="'sp.logo_tooltip' | translate" matTooltipPosition="right">
    <a [routerLink]="['/']" (click)="analytics.sendEvent('navbar', 'landing');">
      <img [alt]="'sp.logo_tooltip' | translate" [src]="'sp.header.logo' | translate">
    </a>
  </div>

  <div *ngIf="state.atHome() && !appSettings.landingPage && appSettings.navbarLogoOnHome" class="app-home-logo" [class.app-col-hidden]="mobileSearchBarExpanded">
    <app-logo></app-logo>
  </div>
  
  <div class="col app-col-right" [class.app-col-right-home]="state.atHome()" [class.app-search-bar-displayed]="mobileSearchBarExpanded">
    <app-navbar-search-bar *ngIf="!state.atHome()" [class.app-search-bar-show]="mobileSearchBarExpanded"></app-navbar-search-bar>
    <nav class="app-buttons">
      <ul>
        <li class="app-navbar-item">
          <ng-container *ngIf="appSettings.k5Compat(); else newCollections">
            <a (click)="analytics.sendEvent('navbar', 'collections');" *ngIf="!appSettings.richCollections" [routerLink]="appSettings.getRouteFor('browse')" [queryParams]="{category: 'collections'}">{{ 'navbar.collections' | translate }}</a>
            <a (click)="analytics.sendEvent('navbar', 'collections');" *ngIf="appSettings.richCollections" [routerLink]="appSettings.getRouteFor('collections')">{{ 'navbar.collections' | translate }}</a>
          </ng-container>
          <ng-template #newCollections>
            <a *ngIf="appSettings.availableDoctype('collection')" (click)="analytics.sendEvent('navbar', 'collections');" [routerLink]="appSettings.getRouteFor('search')" [queryParams]="{doctypes: 'collection'}">{{ 'navbar.collections' | translate }}</a>
          </ng-template>
        </li>
        <li class="app-navbar-item">
          <a (click)="analytics.sendEvent('navbar', 'browse');" [routerLink]="appSettings.getRouteFor('browse')">{{ 'navbar.browse' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.aboutPage">
          <a (click)="analytics.sendEvent('navbar', 'about');" [routerLink]="appSettings.getRouteFor('about')">{{ 'navbar.about' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.faqPage">
          <a (click)="analytics.sendEvent('navbar', 'faq');" [routerLink]="appSettings.getRouteFor('faq')">{{ 'navbar.faq' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.impressumPage">
          <a (click)="analytics.sendEvent('navbar', 'impressum');" [routerLink]="appSettings.getRouteFor('impressum')">{{ 'navbar.impressum' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="authService.isLoggedIn() && appSettings.folders">
          <a (click)="analytics.sendEvent('navbar', 'folders');" [routerLink]="appSettings.getRouteFor('folders')">{{ 'navbar.folders' | translate }}</a>
        </li>
        <ng-container *ngIf="appSettings.auth || appSettings.krameriusLogin || appSettings.keycloak">
          <li class="app-navbar-item" *ngIf="authService.isLoggedIn()">
            <a tabindex="0" (click)="logout()">{{ 'navbar.logout' | translate }}</a>
          </li>
          <li class="app-navbar-item" *ngIf="!authService.isLoggedIn()">
            <a tabindex="0" (click)="login()">{{ 'navbar.login' | translate }}</a>
          </li>
        </ng-container>
        <li class="app-language">
          <div tabindex="0" role="button" *ngIf="languages().length > 1" class="app-dropdown-language" [matMenuTriggerFor]="languagesMenu">
            <img [alt]="languageService.getLanguageName(translate.currentLang)"  src="assets/img/flag_{{translate.currentLang}}.png" >
          </div>
        </li>
      </ul>
    </nav>
    <div role="button" class="app-mobile-menu-trigger" [matMenuTriggerFor]="mobileMenu" [class.app-home]="state.atHome()" >
      <mat-icon>more_vert</mat-icon>
    </div>
    <a tabindex="0" role="button" class="app-btn-search-bar" (click)="toggleMobileSearchBar()" *ngIf="!state.atHome()">
      <mat-icon *ngIf="!mobileSearchBarExpanded">search</mat-icon>
      <mat-icon *ngIf="mobileSearchBarExpanded">close</mat-icon>
    </a>
  </div>
</div>


<mat-menu #mobileMenu="matMenu" xPosition="after" class="app-mobile-menu">
  <ng-template matMenuContent>
    <nav>
      <ul>
        <li class="app-navbar-item">
          <ng-container *ngIf="appSettings.k5Compat(); else newCollections">
            <a (click)="analytics.sendEvent('navbar', 'collections');" *ngIf="!appSettings.richCollections" [routerLink]="appSettings.getRouteFor('browse')" [queryParams]="{category: 'collections'}">{{ 'navbar.collections' | translate }}</a>
            <a (click)="analytics.sendEvent('navbar', 'collections');" *ngIf="appSettings.richCollections" [routerLink]="appSettings.getRouteFor('collections')">{{ 'navbar.collections' | translate }}</a>
          </ng-container>
          <ng-template #newCollections>
            <a *ngIf="appSettings.availableDoctype('collection')" (click)="analytics.sendEvent('navbar', 'collections');" [routerLink]="appSettings.getRouteFor('search')" [queryParams]="{doctypes: 'collection'}">{{ 'navbar.collections' | translate }}</a>
          </ng-template>
        </li>
        <li class="app-navbar-item">
          <a (click)="analytics.sendEvent('navbar', 'browse');" [routerLink]="appSettings.getRouteFor('browse')">{{ 'navbar.browse' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.aboutPage">
          <a (click)="analytics.sendEvent('navbar', 'about');" [routerLink]="appSettings.getRouteFor('about')">{{ 'navbar.about' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.faqPage">
          <a (click)="analytics.sendEvent('navbar', 'faq');" [routerLink]="appSettings.getRouteFor('faq')">{{ 'navbar.faq' | translate }}</a>
        </li>
        <li class="app-navbar-item" *ngIf="appSettings.impressumPage">
          <a (click)="analytics.sendEvent('navbar', 'impressum');" [routerLink]="appSettings.getRouteFor('impressum')">Impressum</a>
        </li>
        <li class="app-navbar-item" *ngIf="authService.isLoggedIn() && appSettings.folders">
          <a (click)="analytics.sendEvent('navbar', 'folders');"  [routerLink]="appSettings.getRouteFor('folders')">{{ 'navbar.folders' | translate }}</a>
        </li>
        <ng-container *ngIf="appSettings.auth || appSettings.krameriusLogin || appSettings.keycloak">
          <li class="app-navbar-item" *ngIf="authService.isLoggedIn()">
            <a tabindex="0" (click)="logout()">{{ 'navbar.logout' | translate }}</a>
          </li>
          <li class="app-navbar-item" *ngIf="!authService.isLoggedIn()">
            <a tabindex="0" (click)="login()">{{ 'navbar.login' | translate }}</a>
          </li>
        </ng-container>

        <li tabindex="0" (click)="$event.preventDefault();$event.stopPropagation()" *ngIf="languages().length > 1" class="app-language" [matMenuTriggerFor]="languagesMenu">
          <img src="assets/img/flag_{{translate.currentLang}}.png">
        </li>
      </ul>
    </nav>


  </ng-template>
</mat-menu>



<mat-menu #languagesMenu="matMenu" xPosition="after" class="app-languages-menu">
  <ul [class.app-more-cols]="languages().length >= 6">
    <li class="app-dropdown-item" mat-menu-item tabindex="0" *ngFor="let lang of languages()" (keydown.enter)="closeMenu()" (click)="onLanguageChanged(lang)" role="button">
      <div class="app-language">
        <img src="assets/img/flag_{{lang}}.png">
        <div class="app-language-name">{{ languageService.getLanguageName(lang) }}</div>
      </div>
    </li>
  </ul>
</mat-menu>
