<div class="app-metadata-dialog app-dialog">

  <div class="app-tabs" *ngIf="selection">
    <a *ngIf="url" style="float: right;" target="_blank" [href]="url">URL</a>
    <div tabindex="0" [matMenuTriggerFor]="resourceMenu" class="app-dialog-title">{{ resource }}</div>
    <div *ngFor="let item of items" class="app-tab" [class.active]="selection.type === item.type" tabindex="0" (click)="changeTab(item)">
      <div *ngIf="translate.currentLang == 'de'">
        {{ 'model.' + item.type | translate }}
      </div>
      <div *ngIf="translate.currentLang !== 'de'">
        {{ 'model.' + item.type | translate | lowercase }}
      </div>
    </div>
  </div>

  <div class="app-dialog-content" *ngIf="selection">
      <div class="app-tab-content" *ngIf="selection[resource]">
          <code [highlight]="selection[resource]"></code>
      </div>
      <mat-spinner *ngIf="!selection[resource]"
        [strokeWidth]="2" 
        [diameter]="20">
      </mat-spinner>
  </div>

  <div class="app-dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'common.close' | translate }}</button>
  </div>

</div>

<mat-menu #resourceMenu="matMenu" xPosition="after" class="app-dropdown-menu">
  <ng-template matMenuContent>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('mods')">mods</div>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('dc')">dc</div>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('solr')">solr</div>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('foxml')">foxml</div>
    <mat-divider></mat-divider>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('alto')">alto</div>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('ocr')">ocr</div>
    <mat-divider></mat-divider>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('item')">item</div>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('children')">children</div>
    <mat-divider></mat-divider>
    <div tabindex="0" mat-menu-item class="app-dropdown-item" (click)="changeResource('iiif')">iiif</div>
  </ng-template>
</mat-menu>