<a [routerLink]="settings.getRouteFor(item.getPath())" [queryParams]="item.getQuery()" (click)="analytics.sendEvent('periodical', 'open from', 'fulltext');">
  <div class="app-card-content-wrapper">
      <div class="app-card-media" >
        <div class="app-card-thumb" [style.background-image]="getThumb()">
          <div matTooltipPosition="above" [matTooltip]="lock.tooltip" class="app-lock {{ lock.class }}" *ngIf="lock">
            <mat-icon>{{ lock.icon }}</mat-icon>
          </div>
        </div>
      </div>

      <div class="app-card-content">
        <ng-container *ngIf="item.type === 'page' || item.type === 'article'">
          <div class="app-card-row">
            <div class="app-card-row-right">{{item.year}}</div>
            <div class="app-card-row-left">{{ 'metadata.volume' | translate }} {{item.volume}}</div>
          </div>
          <div class="app-card-row">
            <div class="app-card-row-right">{{item.date}}</div>
            <div class="app-card-row-left" *ngIf="item.issue">{{ 'metadata.issue' | translate }} {{item.issue}}</div>
            <div class="app-card-row-left" style="-webkit-box-orient: vertical;" *ngIf="item.supplement">{{ 'model.supplement' | translate }} {{item.supplement}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="item.type === 'monograph_unit_page'">
          <div class="app-card-row app-card-row-2col">
            <div class="app-card-row-left">
              <span *ngIf="item.part">{{item.part}}. </span>
              {{item.title}}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="item.type === 'omnibus_unit_page'">
          <div class="app-card-row app-card-row-2col">
            <div class="app-card-row-left">{{item.title}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="item.type === 'monograph_unit'">
          <div class="app-card-title">
            <span *ngIf="item.part">{{item.part}}. </span>
            {{item.title}}
          </div>
          <div class="app-card-doctype app-card-monograph">{{ 'model.monograph' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="item.type === 'omnibus_unit'">
          <div class="app-card-title">{{item.title}}</div>
          <div class="app-card-doctype app-card-{{item.model}}">{{ 'model.' + item.model | translate }}</div>
        </ng-container>
  
        <ng-container *ngIf="item.type === 'article'">
          <div class="app-card-row app-main-title">{{item.title}}</div>
          <div class="app-card-row">{{ item.authors }}</div>
          <div class="app-card-doctype app-card-article">{{ 'model.article' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="item.type === 'page' || item.type === 'monograph_unit_page' || item.type === 'omnibus_unit_page'">
          <div class="app-card-row">
            <div class="app-card-row-right"></div>
            <div class="app-card-row-left" *ngIf="item.page">{{ 'metadata.page' | translate }} {{item.page}}</div>
          </div>
          <div class="app-card-ocr" [innerHTML]="item.text"></div>
        </ng-container>

      </div>

    </div>
</a>
