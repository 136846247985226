<div class="app-music-playlist-wrapper">
 
 
 

  <div class="app-tracks">
    <div class="app-track" [class.app-active]="musicService.isActiveTrack(track)" (click)="musicService.selectTrack(track)" *ngFor="let track of musicService.tracks; let index = index;">
        <mat-icon (click)="musicService.downloadTrack($event, track)" [matTooltip]="'music.download_track' | translate" class="app-download-button" *ngIf="musicService.downloadedTrack !== track">download</mat-icon>
        <div class="app-track-left">
            <div class="app-track-number">{{ index + 1 }}</div>
            <mat-icon *ngIf="!musicService.isActiveTrack(track) || !musicService.isPlaying()">play_arrow</mat-icon>
            <mat-icon *ngIf="musicService.isActiveTrack(track) && musicService.isPlaying()">pause</mat-icon>
        </div>
        <div class="app-track-label">
            <div class="app-track-title">
                <mat-icon class="app-icon-lock" *ngIf="!track.isPublic">lock</mat-icon>
                {{ track.name }}
            </div>
            <ng-container *ngIf="track.unit">
                <div class="app-divider"></div>
                <div class="app-track-unit">{{ track.unit.name }}</div>
            </ng-container>
            <div style="flex: 1"></div>
            <div class="app-track-length" *ngIf="track.length">{{ track.length * 1000| date:'m:ss' }}</div>
        </div>
    </div>
</div>
