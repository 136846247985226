<div class="app-form-banner" *ngIf="show()">
  
  <div class="app-form-banner-content">
    <div class="app-form-banner-image">
      <img src="assets/img/form_laptop2.png" alt="banner">
    </div>
    <div class="app-form-banner-text">
      <div class="app-form-banner-title">Pomozte nám vylepšit Digitální knihovnu</div>

      <div class="app-form-banner-description">Připravujeme nové rozhraní Digitální knihovny. Zajímá nás, jak ji používáte a co vám chybí. Vyplňte krátký dotazník a řekněte nám, co je pro vás důležité.</div>
      <button (click)="open()" mat-flat-button color="primary">Vyplnit dotazník</button>
    </div>



</div>

