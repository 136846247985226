<a *ngIf="item.uuid" [routerLink]="item.path" [queryParams]="{ bb: item.bb }">
  <div class="app-card-content-wrapper">
    <div class="app-card-media">
      <div class="app-card-thumb" [style.background-image]="getThumb()"></div>
    </div>

    <div class="app-card-content">
      <div *ngIf="item.title" class="app-card-title">
        {{ item.title }}
      </div>
      
      <div *ngIf="item.description" class="app-card-description">
        {{ item.description }}
      </div>
    </div>
  </div>

</a>


<a *ngIf="!item.uuid" [href]="item.url" target="_blank">
  <div class="app-card-content-wrapper">
    <div class="app-card-media">
      <div class="app-card-thumb" [style.background-image]="getThumb()"></div>
    </div>

    <div class="app-card-content">
      <div *ngIf="item.title" class="app-card-title">
        {{ item.title }}
      </div>
      
      <div *ngIf="item.description" class="app-card-description">
        {{ item.description }}
      </div>
    </div>
  </div>

</a>