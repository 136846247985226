<app-book-controls  class="hide-on-small-only"></app-book-controls>



<ng-container *ngIf="bookService.isImage()">
<div class="app-navigation-tabs" *ngIf="bookService.navigationTabsCount > 1">

  <div *ngIf="bookService.allPages.length > 0" 
       tabindex="0"
       (click)="bookService.changeNavigationTab('pages');analytics.sendEvent('viewer', 'navigation', 'pages')"
       [class.app-active]="bookService.activeNavigationTab == 'pages'" 
       class="app-tab app-active app-tab-{{bookService.navigationTabsCount}}">
    <span class="app-desc">{{ 'model_plural.page' | translate }}</span>
  </div>

  <div *ngIf="bookService.articles.length > 0" 
      tabindex="0"
       (click)="bookService.changeNavigationTab('articles');analytics.sendEvent('viewer', 'navigation', 'articles')"
       [class.app-active]="bookService.activeNavigationTab === 'articles'" 
       class="app-tab app-tab-{{bookService.navigationTabsCount}}">
    <span class="app-desc">{{ 'model_plural.article' | translate }}</span>
  </div>

  <div *ngIf="bookService.internalParts.length > 0" 
    tabindex="0"
       (click)="bookService.changeNavigationTab('internalparts');analytics.sendEvent('viewer', 'navigation', 'internalparts')"
       [class.app-active]="bookService.activeNavigationTab === 'internalparts'" 
       class="app-tab app-tab-{{bookService.navigationTabsCount}}">
    <span class="app-desc">{{ 'model_plural.internalpart' | translate }}</span>
  </div>

  <div *ngIf="bookService.metadata.doctype == 'convolute' && bookService.extraParents.length > 0 && !bookService.getFulltextQuery()" 
    tabindex="0"
       (click)="bookService.changeNavigationTab('konvolutparts');analytics.sendEvent('viewer', 'navigation', 'konvolutparts')"
       [class.app-active]="bookService.activeNavigationTab === 'konvolutparts'" 
       class="app-tab app-tab-{{bookService.navigationTabsCount}}">
    <span class="app-desc">{{ 'model_plural.convolutepart' | translate }}</span>
  </div>
</div>

<div class="app-navigation-tab-content" *ngIf="bookService.activeNavigationTab === 'articles'">
  <div tabindex="0" *ngFor="let article of bookService.articles" (click)="bookService.onArticleSelected(article)" class="app-item" [class.app-selected]="article === bookService.article">{{ article.title }}</div>
</div>

<div class="app-navigation-tab-content" *ngIf="bookService.activeNavigationTab === 'internalparts'">
  <div tabindex="0" *ngFor="let internalPart of bookService.internalParts" (click)="bookService.onInternalPartSelected(internalPart)" class="app-item" [class.app-selected]="internalPart === bookService.internalPart">{{ internalPart.title }}</div>
</div>

<div class="app-navigation-tab-content" *ngIf="bookService.activeNavigationTab === 'konvolutparts'">
  <div tabindex="0" *ngFor="let parent of bookService.extraParents" (click)="bookService.onParentSelected(parent)" class="app-item" [class.app-selected]="bookService.getPage().parentUuid === parent.pid">{{ parent.title }}</div>
</div>

<app-book-search [class.app-hidden]="bookService.activeNavigationTab !== 'pages'"></app-book-search>

<div class="app-navigation-header" *ngIf="bookService.getPageCount() > 1" [class.app-hidden]="bookService.activeNavigationTab !== 'pages'">
  <span>{{ 'metadata.page' | translate }}</span> 
  <label for="page-number" class="app-hidden-label">{{ 'common.set_page_number' | translate }}</label>
  <input id="page-number" class="app-current-page" (keyup)="onKeyUp($event)" type="text" pattern="[0-9]*" value="23" [(ngModel)]="pageIndex" [matTooltip]="'common.set_page_number' | translate" />
  <span class="app-divider">/</span>
  <span>{{bookService.getPageCount()}}</span>
</div>

<!-- <div class="app-navigation-pages" id="app-navigation-container" [class.app-is-query]="bookService.getFulltextQuery()" [class.app-hidden]="bookService.activeNavigationTab !== 'pages'">
  <ng-container *ngIf="!bookService.getFulltextQuery() || bookService.fulltextAllPages; else showSnippets">
    <app-navigation-item [page]="page" [container]="container" *ngFor="let page of bookService.pages" (pageSelected)="onPageSelected(page)"></app-navigation-item>
  </ng-container>
  <ng-template #showSnippets>
    <app-navigation-snippet [page]="page" [container]="container" *ngFor="let page of bookService.pages" (pageSelected)="onPageSelected(page)"></app-navigation-snippet>
  </ng-template>
</div> -->

<div class="app-navigation-pages" id="app-navigation-container" [class.app-is-query]="bookService.getFulltextQuery()" [class.app-hidden]="bookService.activeNavigationTab !== 'pages'">
  <h2 class="app-visualy-hidden">{{ 'model_plural.page' | translate }}</h2>
  <ul>
    <li *ngIf="!bookService.getFulltextQuery() || bookService.fulltextAllPages; else showSnippets">
      <app-navigation-item [page]="page" [container]="container" *ngFor="let page of bookService.pages" (pageSelected)="onPageSelected(page)"></app-navigation-item>
    </li>
    <ng-template #showSnippets>
      <li>
        <app-navigation-snippet [page]="page" [container]="container" *ngFor="let page of bookService.pages" (pageSelected)="onPageSelected(page)"></app-navigation-snippet>
      </li>
    </ng-template>
  </ul>
</div>

</ng-container>





<ng-container *ngIf="bookService.isEpub()">

  <div class="app-epub-search-bar-wrapper" style="min-height: 45px;">
    <div class="app-epub-search-bar" [class.app-is-query]="query" *ngIf="epubService.ready">
      <input (keyup.enter)="epubService.onSearch()" type="text" placeholder="Hledat" [(ngModel)]="epubService.searchQuery" />
      <mat-icon tabindex="0" class="app-close" *ngIf="epubService.searchQuery" (click)="epubService.cleanQuery()" [matTooltip]="'searchbar.delete_query' | translate">close</mat-icon>
      <mat-icon class="app-magnify" (click)="epubService.onSearch()">search</mat-icon>
    </div>
  </div>

  <div class="app-navigation-header" style="min-height: 44px;">
    <ng-container *ngIf="epubService.totalPages() && !epubService.searching">
      <span>{{ 'metadata.page' | translate }}</span> 
      <label for="page-number-epub" class="app-hidden-label">{{ 'common.set_page_number' | translate }}</label>
      <input id="page-number-epub" class="app-current-page" [value]="epubService.location ? epubService.location.page : 0" (change)="epubService.goToPage($event.target.value)" type="text" pattern="[0-9]*" [matTooltip]="'common.set_page_number' | translate" />
      <span class="app-divider">/</span>
      <span>{{ epubService.totalPages() }}</span>
      <mat-icon tabindex="0" style="float:right; line-height: inherit; cursor: pointer; color: #888;" (click)="epubTocExpanded = !epubTocExpanded">{{ epubTocExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </ng-container>
  </div>

  <div class="app-navigation-toc" *ngIf="!epubService.searching">
    <ng-container *ngFor="let item of epubService.toc">
      <div tabindex="0" class="app-item" [class.app-selected]="epubService.isChapterSelected(item)" (click)="epubService.goToChapter(item)">{{ item.label.trim() }}</div>
      <ng-container *ngIf="epubTocExpanded">
        <ng-container *ngFor="let subitem of item.subitems">
          <div tabindex="0" class="app-item app-sub" (click)="epubService.goToChapter(subitem)">{{ subitem.label.trim() }}</div>
          <div tabindex="0" *ngFor="let subsubitem of subitem.subitems" class="app-item app-sub-sub" (click)="epubService.goToChapter(subsubitem)">{{ subsubitem.label.trim() }}</div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="app-navigation-toc" *ngIf="epubService.searching">
    <div tabindex="0" *ngFor="let item of epubService.searchResults" class="app-item" (click)="epubService.goToSnippet(item)">
      <span [innerHTML]="item.snippet | safeHtml"></span>
    </div>
  </div>

</ng-container>







<ng-container *ngIf="bookService.isPdf()">

  <div class="app-navigation-articles" *ngIf="bookService.activeNavigationTab === 'articles'; else pdfNav">
    <div tabindex="0" *ngFor="let article of bookService.articles" (click)="bookService.onArticleSelected(article)" class="app-article" [class.app-selected]="article === bookService.article">{{ article.title }}</div>
  </div>

  <ng-template #pdfNav>
    <div class="app-epub-search-bar-wrapper" style="min-height: 45px;">
      <div class="app-epub-search-bar" [class.app-is-query]="query" *ngIf="pdfService.totalPages">
        <input (keyup.enter)="pdfService.onSearch()" type="text" placeholder="Hledat" [(ngModel)]="pdfService.searchQuery" />
        <mat-icon tabindex="0" class="app-close" *ngIf="pdfService.searchQuery" (click)="pdfService.cleanQuery()" [matTooltip]="'searchbar.delete_query' | translate">close</mat-icon>
        <mat-icon class="app-magnify" (click)="pdfService.onSearch()">search</mat-icon>
      </div>
    </div>
  
    <div class="app-navigation-header" style="min-height: 44px;">
      <ng-container *ngIf="pdfService.totalPages && !pdfService.searching">
        <span>{{ 'metadata.page' | translate }}</span> 
        <label for="page-number-pdf" class="app-hidden-label">{{ 'common.set_page_number' | translate }}</label>
        <input id="page-number-pdf" class="app-current-page" [value]="pdfService.pageIndex" (change)="pdfService.goToPage($event.target.value)" type="text" pattern="[0-9]*" [matTooltip]="'common.set_page_number' | translate"/>
        <span class="app-divider">/</span>
        <span>{{ pdfService.totalPages }}</span>
        <mat-icon tabindex="0" style="float:right; line-height: inherit; cursor: pointer; color: #888;" (click)="epubTocExpanded = !epubTocExpanded">{{ epubTocExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </ng-container>
    </div>

    <div class="app-navigation-toc" *ngIf="!pdfService.searching">
      <ng-container *ngFor="let item of pdfService.outline">
        <div tabindex="0" class="app-item" [class.app-selected]="pdfService.isChapterActive(item, epubTocExpanded)" (click)="pdfService.goTo(item.dest)">{{ item.title.trim() }}</div>
        <ng-container *ngIf="epubTocExpanded">
          <ng-container *ngFor="let subitem of item.items">
            <div tabindex="0" class="app-item app-sub" [class.app-selected]="pdfService.isChapterActive(subitem)" (click)="pdfService.goTo(subitem.dest)">{{ subitem.title.trim() }}</div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

</ng-container>


