<div class="app-book-search-bar-wrapper">
  <div class="app-book-search-bar" [class.app-is-query]="fulltextQuery">
    <label for="document-search" class="app-hidden-label">{{ 'searchbar.document' | translate }}</label>
    <ng2-completer #completer (keyup)="onKeyUp($event)" (selected)="onSelected($event)" [(ngModel)]="fulltextQuery" [matchClass]="'highlight'"
    [datasource]="service" [minSearchLength]="2" [inputId]="'document-search'" [inputClass]="'autocomplete'" [placeholder]="'searchbar.document' | translate" [inputName]="'autocomplete-input'">
    </ng2-completer>
    <button (click)="onMagnifyIconClick()" class="app-button app-button-search" mat-icon-button [attr.aria-label]="'searchbar.search' | translate" [matTooltip]="'searchbar.search' | translate">
      <mat-icon>search</mat-icon>
    </button>
    <button *ngIf="fulltextQuery" (click)="cleanQuery()" class="app-button app-button-close" mat-icon-button [attr.aria-label]="'searchbar.delete_query' | translate" [matTooltip]="'searchbar.delete_query' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-checkbox *ngIf="bookService.getFulltextQuery()" color="primary" (change)="checkboxChanged()" [(ngModel)]="bookService.fulltextAllPages">{{ 'book_controls.all_pages' | translate }}</mat-checkbox>
</div>