<div class="app-advanced-search-dialog app-dialog">

  <h1 class="app-dialog-title">{{ 'advanced_search.title' | translate }}</h1>

  <div class="app-dialog-content">

    <label>{{ 'advanced_search.field.field' | translate }}</label>

    <div class="app-field-type-wrapper" tabindex="0" role="button" [matMenuTriggerFor]="fieldMenu">
      <div class="app-field-type">{{ 'advanced_search.field.' + fieldType | translate }}</div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <label for="advanced-search-input">{{ 'advanced_search.field.value' | translate }}</label>
    <input id="advanced-search-input"
        (keyup.enter)="onSearch()" 
        type="text" 
        [placeholder]="'advanced_search.field.value_placeholder' | translate"
        [(ngModel)]="fieldValue" />

  </div>
 
  <div class="app-dialog-actions">
    <button style="float: left" mat-flat-button color="primary" (click)="onSearch()" [disabled]="!fieldValue">{{'advanced_search.field.search' | translate}}</button>
    <button mat-button (click)="onCancel()">{{ 'common.cancel' | translate }}</button>
  </div>

</div>





<mat-menu #fieldMenu="matMenu" xPosition="after" class="app-dropdown-menu">
  <ng-template matMenuContent>
    <ul>
      <li *ngFor="let field of fields">
        <div mat-menu-item tabindex="0" role="button" class="app-dropdown-item" (click)="changeField(field)">{{ 'advanced_search.field.' + field | translate }}</div>
      </li>
    </ul>
  </ng-template>
</mat-menu>