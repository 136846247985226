<div class="app-dialog-wrapper">
    <div mat-dialog-content>
        <div class="app-dialog-title">{{ data.title | translate }} ({{data.uuids.length}})</div>
        <div class="app-dialog-message">{{ data.message | translate }}</div>
      </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="warn" [mat-dialog-close]="data.uuids" cdkFocusInitial>{{ 'folders.dialogs.delete' | translate }}</button>
        <button mat-button (click)="onNoClick()">{{ 'folders.dialogs.cancel' | translate }}</button>
    </div>
</div>
