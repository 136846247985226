<div id="app-viewer" class="app-viewer-wrapper" (mousemove)="onMouseMove()" (click)="onMouseMove()">
<app-viewer-controls *ngIf="bookService.pageAvailable" [class.hide-on-inactivity]="hideOnInactivity"></app-viewer-controls>
<ng-container *ngIf="bookService.isPageInaccessible()">
    <app-licence-window [licences]="bookService.licences"></app-licence-window>
</ng-container>

<app-alert *ngIf="bookService.isPageFailure()"
    type="danger"
    label="alert.error"
    message="alert.failed_to_load_pdf">
</app-alert>

<pdf-viewer 
    *ngIf="pdf.pdfObject" [src]="pdf.pdfObject"
    [render-text]="true"
    [render-text-mode]="2"
    [rotation]="rotation"
    [stick-to-page]="true"
    [show-all]="true"
    [zoom]="pdf.zoom"
    [(page)]="pdf.pageIndex"
    [zoom-scale]="zoomScale"
    [autoresize]="true"
    [original-size]="false"
    (page-rendered)="pageRendered($event)"
    (error)="onError($event)"
    (after-load-complete)="afterLoadComplete($event)"
    >
</pdf-viewer>


<div class="app-selection-actions" 
      [class.hide-on-inactivity]="hideOnInactivity"
      *ngIf="showPageActions()">

      <ng-container>

            <mat-icon
                  class="app-selection app-main-selection app-hide-on-hover">
                  auto_awesome
            </mat-icon>

            <mat-icon
                  [matTooltip]="'viewer_page.page_text' | translate"
                  matTooltipPosition="left"
                  (click)="onPageOcr()"
                  *ngIf="aiActionsAvailable()"
                  class="app-selection">
                  title
            </mat-icon>

            <mat-icon
                  [matTooltip]="'viewer_page.page_read' | translate"
                  matTooltipPosition="left"
                  (click)="onReadPage()"
                  *ngIf="aiActionsAvailable()"
                  [class.app-inactive]="tts.inProgress()"
                  class="app-selection app-type-ai">
                  record_voice_over
            </mat-icon>

            <mat-icon
                  [matTooltip]="'viewer_page.page_translate' | translate"
                  matTooltipPosition="left"
                  (click)="onTranslatePage()"
                  *ngIf="aiActionsAvailable()"
                  class="app-selection app-type-ai">
                  translate
            </mat-icon>

             
            <mat-icon
                  [matTooltip]="'viewer_page.page_summarize' | translate"
                  matTooltipPosition="left"
                  (click)="onSummarizePage()"
                  *ngIf="aiActionsAvailable()"
                  class="app-selection app-type-ai">
                  list
            </mat-icon>
      </ng-container>

</div>


<div class="app-sound-controls" *ngIf="tts.inProgress()"
      [class.hide-on-inactivity]="hideOnInactivity && !tts.isLoading()">

      <mat-icon class="app-rotate-icon" 
      *ngIf="tts.isLoading()">
      hourglass_top
</mat-icon>

<ng-container *ngIf="!tts.isLoading()">

            <mat-icon
                  *ngIf="!tts.paused()"
                  [matTooltip]="'viewer_page.read_pause' | translate"
                  matTooltipPosition="below"
                  (click)="tts.pause()">
                  pause
            </mat-icon>

            <mat-icon *ngIf="tts.paused()"
                  [matTooltip]="'viewer_page.read_resume' | translate"
                  matTooltipPosition="below"
                  (click)="tts.resume()">
                  play_arrow
            </mat-icon>

            <mat-icon
                  [matTooltip]="'viewer_page.read_stop' | translate"
                  matTooltipPosition="below"
                  (click)="tts.stop()">
                  stop
            </mat-icon>

            <mat-icon *ngIf="tts.continuing"
                  [matTooltip]="'viewer_page.read_next' | translate"
                  matTooltipPosition="below"
                  (click)="tts.skipNext()">
                  skip_next
      </mat-icon>
    </ng-container>

</div>


<mat-icon class="app-rotate-icon app-main-service-loader" *ngIf="bookService.serviceLoading">hourglass_top</mat-icon>


<span *ngIf="bookService.showNavigationPanel && controlsService.leftPanelVisible && !controlsService.fullscreenEnabled()"
class="app-handle app-left-handle valign-wrapper hide-on-small-only" 
(click)="controlsService.hideLeftPanel()" 
[matTooltip]="'view_controls.tooltip_hide_left' | translate">
<mat-icon>arrow_left</mat-icon>
</span>

<span *ngIf="bookService.showNavigationPanel && !controlsService.leftPanelVisible && !controlsService.fullscreenEnabled()"
class="app-handle app-left-handle valign-wrapper hide-on-small-only" 
(click)="controlsService.showLeftPanel()" 
[matTooltip]="'view_controls.tooltip_show_left' | translate">
<mat-icon>arrow_right</mat-icon>
</span>

<span *ngIf="controlsService.rightPanelVisible && !controlsService.fullscreenEnabled()"
    class="app-handle app-right-handle valign-wrapper hide-on-small-only" 
    (click)="controlsService.hideRightPanel()" 
    [matTooltip]="'view_controls.tooltip_hide_right' | translate">
    <mat-icon>arrow_right</mat-icon>
</span>

<span *ngIf="!controlsService.rightPanelVisible && !controlsService.fullscreenEnabled()"
class="app-handle app-right-handle valign-wrapper hide-on-small-only" 
(click)="controlsService.showRightPanel()" 
[matTooltip]="'view_controls.tooltip_show_right' | translate">
<mat-icon>arrow_left</mat-icon>
</span>

<mat-spinner *ngIf="pdf.pdfLoading" 
    class="medium-centered-spinner" 
    [strokeWidth]="3"
    [diameter]="40">
</mat-spinner>

<div *ngIf="bookService.licence && licences.bar(bookService.licence)" class="app-licence-bar">
    <span class="app-info">{{ licences.label(bookService.licence) }}</span>
    <span class="app-user">
          <ng-container *ngIf="authService.isLoggedIn()"> {{ authService.getUserId() || authService.getUserName() }} | </ng-container>
          {{ today() }}
    </span>
</div>

</div>