<div tabindex="0" role="button" class="app-item app-display-{{ page.display }}" id="page-id-{{page.uuid}}" (click)="onPageClicked()" [class.app-active]="page.selected">
  <div class="app-item-thumb" [scrollTarget]="container" [lazyLoad]="page.thumb">
    <div *ngIf="lock" class="app-lock {{ lock.class }}">
      <mat-icon>{{ lock.icon }}</mat-icon>
    </div>
  </div>
  <div class="app-item-title">
    {{ page.number }}
  </div>
  <div class="app-item-type" *ngIf="page.title || page.showPageType()">
    <div *ngIf="page.showPageType()">
    {{ 'page_type.' + page.type | translate }}
    </div>
    <div *ngIf="page.title">
      {{ page.title }}
      </div>
  </div>
</div>