<div class="app-search-results-wrapper">
    <div class="app-search-results" [ngClass]="{'app-search-collection': searchService.collection, 'app-display-table': searchService.contentTypeDisplay === 'table'}">
        <ng-container *ngIf="searchService.collection">
            <div class="app-collection-navigation">
                <ng-container *ngIf="searchService.collectionStructure && searchService.collectionStructure.ready">
                    <div *ngFor="let collection of searchService.collectionStructure.collections" class="app-collection-navigation-row">
                        <mat-icon class="app-collection-navigation-icon" (click)="getSnapshot()">home</mat-icon>
                        <span>&gt;</span>
                        <div class="app-collection-navigation-item" *ngFor="let item of collection; let last = last;">
                            <ng-container *ngIf="!last; else ll;">
                                <a routerLink="{{ item.url }}">{{ searchService.getCollectionNavTitle(item) }}</a>
                                <span>&gt;</span>
                            </ng-container>
                            <ng-template #ll>
                                {{ searchService.getCollectionNavTitle(item) }}
                            </ng-template>
                        </div>
                    </div>     
                </ng-container>
            </div>
    
            <div class="app-collection-description"
            [innerHTML]="searchService.getCollectionContent() | safeHtml">
            </div>

            <div class="app-tabs" *ngIf="searchService.displayTabs">
                <div class="app-tab" [class.active]="searchService.activeTab === 'documents'" (click)="changeTab('documents')">Dokumenty</div>
                <div class="app-tab" [class.active]="searchService.activeTab === 'cuttings'"  (click)="changeTab('cuttings')">Výstřižky</div>
            </div>

            <div style="clear:both;" *ngIf="searchService.collectionCuttings && searchService.collectionCuttings.length > 0 && (!searchService.displayTabs || searchService.activeTab === 'cuttings')">
                <app-cutting-card *ngFor="let cutting of searchService.collectionCuttings" [item]="cutting" ></app-cutting-card>
            </div>
        
        </ng-container>
    
        <app-alert *ngIf="!searchService.loading && searchService.getNumberOfResults() === 0 && (!searchService.collection || (searchService.collectionCuttings && searchService.collectionCuttings.length === 0))"
                type="warning"
                label="alert.warning"
                message="alert.no_results_found">
        </app-alert>

        <!-- IF GRID -->
        <div *ngIf="searchService.contentTypeDisplay === 'grid' && (!searchService.displayTabs || searchService.activeTab === 'documents')">
            <h2 class="app-visualy-hidden">{{ 'search.results' | translate }}</h2>
            <ul>
                <li *ngFor="let item of searchService.results">
                    <app-document-card [ngClass]="{'app-search-collection': searchService.collection}" [selectable]="searchService.adminSelection" [in]="searchService.collection ? 'collection' : 'search'" (open)="onOpen(item)" [item]="item" [display]="'grid'"></app-document-card>
                </li>
            </ul>
        </div>

        <!-- IF TABLE -->
        <div *ngIf="searchService.contentTypeDisplay === 'table'" class="app-table-container">
            <table class="app-table" >
                <!-- <app-document-card *ngFor="let item of searchService.results" [selectable]="searchService.adminSelection" [in]="'search'" [item]="item" [display]="'table'"></app-document-card> -->
                <tr class="app-table-row" *ngFor="let item of searchService.results; let odd=odd; let even=even;" 
                    [ngClass]="{ odd: odd, even: even }">
                    <td class="app-table-col app-table-col-thumb">
                        <div class="app-card-thumb" [style.background-image]="getThumb(item)"></div>
                    </td>
                    <td class="app-table-col app-table-col-title" routerLink="{{item.url}}">
                        <div class="app-col-title" *ngIf="item.title" [title]="item.title" >{{ item.title }}</div>
                    </td>
                    <td class="app-table-col app-table-col-authors">
                        <div class="app-col-authors" *ngIf="item.authors && item.authors[0]" [title]="item.authors[0]">{{ item.authors[0] }}</div>
                    </td>
                    <td class="app-table-col app-table-col-date">
                        <div class="app-col-date" *ngIf="item.date">{{ item.date }}</div>
                    </td>
                    <td class="app-table-col app-table-col-doctype">
                        <div *ngIf="item.doctype">{{ item.doctype }}</div>
                    </td>
                    <td class="app-table-col app-table-col-uuid">
                        <input style="position: absolute; left: -9999px; top: -9999px;" id="citation-input" #inputTarget [value]="item.uuid" type="text">
                        <mat-icon tabindex="0" class="app-share-btn" (cbOnSuccess)="onCopied($event)" [ngxClipboard]="inputTarget" [matTooltip]="'common.copy_uuid_to_clipboard' | translate">content_copy</mat-icon>
                    </td>
                </tr>
            </table>
        </div>

        <app-paginator [activeIndex]="searchService.query.page"
                [overallCount]="searchService.getNumberOfResults()"
                [step]="searchService.query.getRows()"
                [displayRows]="displayRows"
                (next)="searchService.nextPage()"
                (previous)="searchService.previousPage()"
                (change)="searchService.onPage($event)"
                (rows)="searchService.setRows($event)">
        </app-paginator> 
        <mat-spinner *ngIf="searchService.loading" class="medium-centered-spinner" [strokeWidth]="3" [diameter]="40"></mat-spinner>
    </div>
    <app-metadata
        class="app-collection-metadata" 
        *ngIf="searchService.collection" 
        [metadata]="searchService.collection">
    </app-metadata>
</div>

