<div class="sp-libraries container">

  <div class="sp-logo">
    <img [src]="'sp.logo' | translate" [alt]="'sp.title' | translate">
  </div>
  <div class="sp-messages">
    <div class="sp-message">{{ 'sp.message' | translate }}</div>
    <div class="sp-message">{{ 'sp.message2' | translate }}</div>
  </div>

  <section class="row" *ngFor="let category of categories">
    <h3 class="sp-section-title">{{ 'sp.category.' + category | translate }}</h3>
    <ul>
      <li class="sp-library-card-wrapper col s12 l6 xl4" *ngFor="let kramerius of libraries[category]">
        <a class="sp-library-card" [routerLink]="['/' + kramerius.code]" (click)="analytics.sendEvent('landing', 'kramerius', settings.getTitleForAnalytics(kramerius));">
          <div class="sp-library-card-content">
              <div class="sp-library-logo" [style.background-image]="getThumb(kramerius.logo)"></div>
              <div class="sp-library-title">{{ settings.getTitleForKramerius(kramerius) }}</div>
          </div>
        </a>
      </li>
    </ul>
  </section>

</div>
